<app-layout-public>
    <div class="splash-card-header w-100 text-center">
        <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
        <div class="splash-card-header-title">Forgot Password</div>
    </div>
    <div class="splash-card-body">
        If you have forgotten your Quotient corporate credentials please contact
        helpdesk@quotient.com for assistance.
    </div>
    <div class="splash-card-footer">
        <a routerLink="/signin">Back to Sign In</a>
    </div>
</app-layout-public>
