import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../services/auth-state.service';
import { AuthErrorType } from '../../../../common/models/auth-api.interface';

@Injectable()
export class ErrorRedirectGuard implements CanActivate {
    constructor(private router: Router, private authStateService: AuthStateService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const alertCode: AuthErrorType = next.queryParams['code'];
        if (alertCode === 'expired' || alertCode === 'unauthenticated') {
            // Save return URL if the error is recoverable after auth refresh
            this.authStateService.saveReturnUrl(next);
        }

        // else activate the route
        return true;
    }
}
