import { skip } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { TicketList } from '../../../../common/models/ticket.interface';
import { ListTicketService } from './list-ticket.service';

@Component({
    selector: 'app-list-tickets',
    styleUrls: ['./list-tickets.component.sass'],
    templateUrl: './list-tickets.component.html'
})
export class ListTicketsComponent implements OnInit, OnDestroy {
    tickets: TicketList[];
    error: any;
    sub: any;
    inProgress = false;
    hero = {
        title: 'Welcome to Quotient Business Support',
        lead: ''
    };

    constructor(private router: Router, private ticketService: ListTicketService) {}

    ngOnInit() {
        this.inProgress = true;
        this.sub = this.ticketService.jiraTickets.pipe(skip(1)).subscribe(data => {
            this.inProgress = false;
            this.tickets = data;
            if (!this.tickets.length) {
                this.router.navigate(['/support/create-ticket']);
            }
        });
        this.ticketService.refresh();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    gotoTicket(key) {
        this.router.navigate(['/support/tickets', key]);
    }
}
