/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-topic.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/hero/hero.component.ngfactory";
import * as i3 from "../../shared/components/hero/hero.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./help-topic.component";
import * as i7 from "./help-topic.service";
var styles_HelpTopicComponent = [i0.styles];
var RenderType_HelpTopicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpTopicComponent, data: {} });
export { RenderType_HelpTopicComponent as RenderType_HelpTopicComponent };
export function View_HelpTopicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row bg-help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "qb-hero-support"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-hero", [], null, null, null, i2.View_HeroComponent_0, i2.RenderType_HeroComponent)), i1.ɵdid(4, 49152, null, 0, i3.HeroComponent, [], { hero: [0, "hero"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "flex-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "section", [["class", "topic-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "mb-2"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, [" < Back to Help "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "h1", [["class", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero; _ck(_v, 4, 0, currVal_0); var currVal_3 = _ck(_v, 14, 0, "/support/help"); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 13).target; var currVal_2 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_1, currVal_2); var currVal_4 = ((_co.topic == null) ? null : _co.topic.topic); _ck(_v, 17, 0, currVal_4); var currVal_5 = ((_co.topic == null) ? null : _co.topic.description); _ck(_v, 18, 0, currVal_5); }); }
export function View_HelpTopicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-topic", [], null, null, null, View_HelpTopicComponent_0, RenderType_HelpTopicComponent)), i1.ɵdid(1, 245760, null, 0, i6.HelpTopicComponent, [i4.ActivatedRoute, i7.HelpTopicService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpTopicComponentNgFactory = i1.ɵccf("app-help-topic", i6.HelpTopicComponent, View_HelpTopicComponent_Host_0, {}, {}, []);
export { HelpTopicComponentNgFactory as HelpTopicComponentNgFactory };
