<div class="row bg-support">
    <div class="col">
        <div class="qb-hero-support">
            <app-hero [hero]="hero"></app-hero>
            <p class="text-center">
                <a
                    href="#"
                    id="ga-create-ticket-1"
                    role="button"
                    class="btn btn-primary"
                    [routerLink]="['/support/create-ticket']"
                >
                    Create New Ticket
                </a>
            </p>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="flex-container">
            <div class="flex-item">
                <app-progress *ngIf="inProgress"></app-progress>
                <div class="card qb-card">
                    <div class="card-header">
                        <h4 class="card-title text-center">Ticket History</h4>
                    </div>
                    <div class="card-body">
                        <div class="qb-table-wrapper">
                            <div class="alert alert-warning" role="alert" *ngIf="error">
                                <strong>{{ error?.status }}</strong>
                                <span>{{ error.message }}</span>
                            </div>

                            <table class="table qb-table" *ngIf="tickets?.length">
                                <thead>
                                    <tr>
                                        <th scope="col">Ticket Number</th>
                                        <th scope="col">Application Name</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of tickets | orderBy: 'created':true">
                                        <td scope="row">
                                            <button
                                                class="btn btn-link qb-btn-link"
                                                (click)="gotoTicket(ticket.key)"
                                            >
                                                {{ ticket.key }}
                                            </button>
                                        </td>
                                        <td>{{ ticket.app }}</td>
                                        <td>{{ ticket.summary }}</td>
                                        <td>{{ ticket.created | date: 'short' }}</td>
                                        <td>{{ ticket.status }}</td>
                                    </tr>
                                </tbody>
                                <tfoot *ngIf="tickets?.length > 10">
                                    <tr>
                                        <td colspan="5">
                                            <strong>Total tickets:</strong> {{ tickets?.length }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                            <p *ngIf="inProgress && !tickets?.length">
                                Fetching Tickets...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
