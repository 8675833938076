<div class="row justify-content-center">
    <!--<div class="col col-sm-11 col-md-9 col-lg-8 col-xl-7">-->
    <div class="col">
        <div class="qb-content-fixed-width">
            <app-progress *ngIf="inProgress"></app-progress>
            <div class="backtolist">
                <a [routerLink]="['/support/tickets']">
                    &lt; View ticket list
                </a>
            </div>
            <div class="card qb-card">
                <div class="card-header">
                    <h4 class="card-title text-center">
                        #{{ ticket?.key }}: {{ ticket?.summary }}
                    </h4>
                </div>
                <div class="card-body">
                    <div class="alert alert-warning" role="alert" *ngIf="error?.status">
                        <strong>{{ error?.status }}</strong>
                        <span>{{ error.message }}</span>
                    </div>
                    <div class="qb-form ml-5 mr-5">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Status</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">{{ ticket?.status }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Opened</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">{{
                                    ticket?.created | date: 'MM/dd/yyyy'
                                }}</span>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="ticket?.resolutiondate">
                            <label class="col-sm-2 col-form-label">Closed</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">
                                    {{ ticket.resolutiondate | date: 'MM/dd/yyyy' }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Type</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">{{ ticket?.issuetype }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Reason</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">{{ ticket?.reason }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">App Name</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext">{{ ticket?.appName }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Description</label>
                            <div class="col-sm-10">
                                <span class="form-control-plaintext white-space-pre">{{
                                    ticket?.description || '-'
                                }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Attachment</label>
                            <div class="col-sm-10">
                                <div class="file-uploader">
                                    <form>
                                        <div class="form-group">
                                            <label
                                                class="btn btn-outline-secondary"
                                                [ngClass]="{ disabled: isTicketClosed() }"
                                            >
                                                Upload a file
                                                <input
                                                    type="file"
                                                    name="attachment"
                                                    multiple="true"
                                                    [disabled]="
                                                        attachment.isUploading || isTicketClosed()
                                                    "
                                                    (change)="onAttachmentChange($event)"
                                                />
                                            </label>
                                            <span *ngIf="attachment.selectedFile">
                                                Uploading file,
                                                <strong>{{ attachment.selectedFile }}</strong>
                                                <span
                                                    *ngIf="attachment.success"
                                                    class="badge badge-primary"
                                                    >Done</span
                                                >
                                                <span
                                                    *ngIf="
                                                        !attachment.success &&
                                                        !attachment.isUploading
                                                    "
                                                    class="badge badge-danger"
                                                    >Failed</span
                                                >
                                            </span>
                                            <app-progress
                                                *ngIf="attachment.isUploading"
                                            ></app-progress>
                                        </div>
                                    </form>
                                </div>
                                <div class="attachments">
                                    <div class="attachment" *ngFor="let att of ticket?.attachments">
                                        <div class="file-container">
                                            <a
                                                href="{{ att.content }}"
                                                target="_blank"
                                                *ngIf="att.filetype[0] === 'image'"
                                            >
                                                <img
                                                    src="{{ att.thumbnail }}"
                                                    title="click to view original size"
                                                    class="thumbnail"
                                                />
                                            </a>
                                            <a
                                                href="{{ att.content }}"
                                                target="_blank"
                                                title="click to download"
                                                *ngIf="att.filetype[0] !== 'image'"
                                            >
                                                <img
                                                    src="../../../assets/icons/file_icon.png"
                                                    title="click tod download"
                                                    class="file-icon"
                                                />
                                            </a>
                                        </div>
                                        <div
                                            class="filename"
                                            title="{{ att.filename }} - {{
                                                att.created | date: 'short'
                                            }}"
                                        >
                                            {{ att.filename | truncname }}
                                        </div>
                                        <!--<div class="date">{{ att.created | date: 'MM/dd/yyyy' }}</div>-->
                                        <div class="filesize">{{ att.size | filesize }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Comment</label>
                            <div class="col-sm-10">
                                <form
                                    class="qb-form"
                                    #commentForm="ngForm"
                                    (ngSubmit)="addComment(commentForm)"
                                    novalidate
                                >
                                    <div class="form-group">
                                        <app-spinner
                                            *ngIf="spinner"
                                            style="position: absolute;"
                                        ></app-spinner>
                                        <textarea
                                            id="comment"
                                            class="form-control"
                                            rows="3"
                                            name="issueComment"
                                            required
                                            [ngModel]="newComment"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                            [disabled]="commentForm.invalid || spinner"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>

                                <div class="qb-comment-list">
                                    <!-- list each comment in a card -->
                                    <div
                                        class="mb-1"
                                        *ngFor="let comment of ticket?.comments | orderBy: 'id'"
                                    >
                                        <hr *ngIf="ticket?.comments.length > 1" />
                                        <strong>{{ comment.author.displayName }}</strong> added a
                                        comment - {{ comment.created | date: 'short' }}
                                        <p class="card-text">{{ comment.body }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
