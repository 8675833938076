import { Component, OnInit } from '@angular/core';
import { TosService } from './tos.service';

@Component({
    selector: 'app-tos',
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.sass']
})
export class TosComponent implements OnInit {
    tosData: {};

    constructor(private tosService: TosService) {}

    ngOnInit() {
        // this.tosService.getTosData()
    }
}
