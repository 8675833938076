import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { OktaAuthService } from '../../shared/services/okta-auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qb-quotient-reset-password',
    templateUrl: './quotient-reset-password.component.html',
    styleUrls: ['../okta.widget.sass']
})
export class QuotientResetPasswordComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {}
}
