var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';
import * as OktaAuth from '@okta/okta-auth-js/dist/okta-auth-js.min.js';
import { AppConfigService } from './app-config.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
/**
 *  This service is initialized before the application bootstrap with okta config data from the server
 *  and checks the current user authentication status.
 *  It also provided okta config data properties.
 */
export class AuthStateService {
    constructor(cookieService, appConfigService) {
        this.cookieService = cookieService;
        this.appConfigService = appConfigService;
        this._idToken = '';
        this.oktaAuth = null;
        this.userClaims = null;
        this.activationHandoff = null;
        this.HANDOFF_TIMEOUT = 10 * 1000;
        this.claimsSubject = new BehaviorSubject(this.userClaims);
        this.maxAllowedRedirects = 3;
        this.redirectThrottlePeriod = 20 * 1000;
    }
    /**
     * init is used in APP_INITIALIZER, hence it needs to return a promise
     */
    init() {
        return this.appConfigService.config
            .pipe(take(1))
            .toPromise()
            .then(appConfig => {
            this.appConfig = appConfig;
            this.defaultOktaConfig = this.getOktaConfig();
            this.oktaAuth = new OktaAuth({
                url: this.defaultOktaConfig.baseUrl,
                clientId: this.defaultOktaConfig.clientId,
                redirectUri: this.appConfig.ssoRedirectUrl
            });
            const cookieIdToken = this.cookieService.get(this.appConfig.ssoCookie) ||
                this.cookieService.get(this.appConfig.ssoTempCookie);
            if (cookieIdToken) {
                // If there is a valid token cookie then add to token manager and validate with
                // Okta via a token refresh.
                let decodedCookieIdToken = this.oktaAuth.token.decode(cookieIdToken);
                this.oktaAuth.tokenManager.add('idToken', {
                    idToken: cookieIdToken,
                    scopes: this.defaultOktaConfig.widgetConfig.authParams.scopes,
                    expiresAt: decodedCookieIdToken.payload.exp
                });
                return this.refreshAuthToken();
            }
            else {
                // No valid token cookie, logout to clear user state and prompt for login.
                this.logout();
                return this;
            }
        })
            .catch((err) => {
            this.logout();
            return this;
        });
    }
    refreshAuthToken() {
        return this.oktaAuth.tokenManager
            .renew('idToken')
            .then(response => {
            this.setCookieAndTokenForState(response.idToken);
            return this;
        })
            .catch((err) => {
            // Token validation with Okta failed, force logout.
            this.logout();
            return this;
        });
    }
    get isReady() {
        return this.appConfig && this.appConfig.ssoCookie;
    }
    get isAuthenticated() {
        // TODO: This should also test for expiration and refresh if possible.
        return this.hasTokenCookie();
    }
    get idToken() {
        return this._idToken;
    }
    get tempToken() {
        return this.cookieService.get(this.appConfig.ssoTempCookie);
    }
    hasTokenCookie() {
        return this.cookieService.get(this.appConfig.ssoCookie);
    }
    hasOnlyTempToken() {
        return (this.cookieService.get(this.appConfig.ssoTempCookie) &&
            !this.cookieService.get(this.appConfig.ssoCookie));
    }
    setTempToken(token) {
        this.cookieService.put(this.appConfig.ssoTempCookie, token, this.getCookieOptions());
        // cache user claims
        this.userClaims = this.oktaAuth.token.decode(token).payload;
    }
    confirmTempToken() {
        let token = this.cookieService.get(this.appConfig.ssoTempCookie);
        this.setIdToken(token);
    }
    setCookieAndTokenForState(token) {
        if (this.cookieService.get(this.appConfig.ssoCookie)) {
            // already authenticated with valid cookie, need to refresh
            this.setIdToken(token);
        }
        else {
            // temp cookie is set or refreshed
            this.setTempToken(token);
        }
    }
    /**
     * sets token in this object, and cookie
     * @param newToken
     */
    setIdToken(newToken) {
        if (!newToken)
            return;
        this._idToken = newToken;
        // remove temp cookie if exists
        if (this.cookieService.get(this.appConfig.ssoTempCookie)) {
            this.cookieService.remove(this.appConfig.ssoTempCookie, this.getCookieOptions());
        }
        // put token cookie for use by all apps
        this.cookieService.put(this.appConfig.ssoCookie, newToken, this.getCookieOptions(true));
        // cache user claims
        this.userClaims = this.oktaAuth.token.decode(this._idToken).payload;
        // clear temp username and persist successful name permanently
        this.tempUsername = null;
        this.saveUsername(this.userClaims.preferred_username);
        // Notify subscribers of changes.
        this.claimsSubject.next(this.userClaims);
    }
    getCookieOptions(setExpires = false) {
        let cookieOpts = {
            domain: this.appConfig.ssoDomain,
            secure: this.appConfig.scheme === 'https'
        };
        if (setExpires) {
            cookieOpts.expires = moment()
                .add(this.appConfig.cookieExpirationDays, 'days')
                .toDate();
        }
        return cookieOpts;
    }
    getUsername() {
        if (this.userClaims && this.userClaims.preferred_username) {
            return this.userClaims.preferred_username;
        }
        else {
            const cookieIdToken = this.cookieService.get(this.appConfig.ssoCookie) ||
                this.cookieService.get(this.appConfig.ssoTempCookie);
            if (cookieIdToken) {
                let decodedCookieIdToken = this.oktaAuth.token.decode(cookieIdToken);
                return decodedCookieIdToken.payload.username;
            }
            else {
                // username not found in cookies
                return null;
            }
        }
    }
    clearAll() {
        if (this.oktaAuth && this.oktaAuth.tokenManager) {
            this.oktaAuth.tokenManager.clear();
        }
        this.cookieService.remove(this.appConfig.ssoCookie, this.getCookieOptions());
        // remove temp cookie if exists
        if (this.cookieService.get(this.appConfig.ssoTempCookie)) {
            this.cookieService.remove(this.appConfig.ssoTempCookie, this.getCookieOptions());
        }
        this.oktaAuth.signOut();
        this.userClaims = null;
        this.tempReturnUrl = null;
        this.tempUsername = null;
        this.redirectLastTimestamp = null;
        this.redirectCounter = null;
        // Notify subscribers of changes.
        this.claimsSubject.next(this.userClaims);
    }
    logout() {
        this.clearAll();
    }
    getOktaConfig(username) {
        let userDomain = username ? username.split('@')[1] : username;
        let match = null;
        this.appConfig.oktaConfigs.forEach(oktaConfig => {
            if (oktaConfig.userDomain == userDomain) {
                match = oktaConfig;
            }
            else if (!match && oktaConfig.isDefault) {
                match = oktaConfig;
            }
        });
        return cloneDeep(match);
    }
    getOktaBaseUrl(username) {
        return this.getOktaConfig(username).baseUrl;
    }
    getUserClaims() {
        return this.userClaims;
    }
    saveReturnUrl(next) {
        // Always save return URL if provided
        if (next.queryParams['returnUrl']) {
            let returnUrl = next.queryParams['returnUrl'];
            if (next.fragment) {
                returnUrl += '#' + next.fragment;
            }
            this.tempReturnUrl = returnUrl;
        }
        // Always save zendesk return URL if provided
        if (next.queryParams['return_to']) {
            this.tempReturnUrl = next.queryParams['return_to'];
        }
    }
    handleQueryParams(router, redirectUrl) {
        let [path, params] = redirectUrl.split('?');
        let paramHash = {};
        if (params.indexOf('&') > -1) {
            // more than one set of params
            params = params.split('&');
            paramHash = params.reduce((accum, currentValue) => {
                const [key, value] = currentValue.split('=');
                accum[key] = value;
                return accum;
            }, {});
        }
        else {
            // only one set of params
            const [key, value] = params.split('=');
            paramHash[key] = value;
        }
        router.navigate([path], { queryParams: paramHash });
        return;
    }
    throttleRedirect() {
        let timeSinceRedirect = this.redirectLastTimestamp
            ? Date.now() - this.redirectLastTimestamp
            : this.redirectThrottlePeriod + 1;
        if (timeSinceRedirect > this.redirectThrottlePeriod) {
            this.redirectCounter = 1;
        }
        else {
            this.redirectCounter = this.redirectCounter ? this.redirectCounter + 1 : 1;
        }
        return this.redirectCounter > this.maxAllowedRedirects;
    }
    handleAuthRedirect(router) {
        // redirect within SPA or to external href
        const redirectUrl = this.tempReturnUrl;
        this.tempReturnUrl = null;
        if (this.throttleRedirect()) {
            router.navigate(['/error'], {
                queryParams: { code: 'throttled' }
            });
            return;
        }
        this.redirectLastTimestamp = Date.now();
        if (redirectUrl && redirectUrl.charAt(0) === '/') {
            if (redirectUrl.indexOf('?') > -1) {
                this.handleQueryParams(router, redirectUrl);
            }
            else {
                router.navigate([redirectUrl]);
            }
            return;
        }
        // handle Zendesk redirect
        if (redirectUrl.includes(this.appConfig.zendeskConfig.baseUrl, 0) ||
            redirectUrl.includes(this.appConfig.zendeskConfig.aliasUrl, 0)) {
            window.location.href = `${this.appConfig.zendeskConfig.redirectUrl}?returnTo=${redirectUrl}`;
            return;
        }
        if (this.isAllowedUrl(redirectUrl)) {
            window.location.href = redirectUrl;
        }
        else {
            // Go home if redirect is invalid
            router.navigate(['/home']);
        }
    }
    /**
     * Restricts URLs that can be redirected or interacted with to known Quotient App URLs only.  This is
     * determined by isolating the base domain hostname and comparing it to environment configured SSO domain.
     * @param {string} redirectUrl - the URL to test
     * @returns {boolean} true - if the URL is allowed to be interacted with
     */
    isAllowedUrl(redirectUrl) {
        if (!redirectUrl || redirectUrl.length === 0) {
            return null;
        }
        if (redirectUrl.indexOf('http') !== 0) {
            // Enforce http/s to be included
            redirectUrl = `${this.appConfig.scheme}://${redirectUrl}`;
        }
        let domainRegStr = this.appConfig.ssoDomain.replace(/\./g, '\\.');
        let urlSecurityReg = new RegExp(`http[s]?://[\\w\\.-]*${domainRegStr}([?\/#:]|$)`);
        return urlSecurityReg.test(redirectUrl);
    }
    handle401Redirect(router) {
        this.logout();
        router.navigate(['/error'], {
            queryParams: { code: 'expired' }
        });
    }
    hasPendingRedirect() {
        return this.tempReturnUrl && this.tempReturnUrl.length > 0;
    }
    saveTempUsername(username) {
        this.savedUsername = null;
        this.tempUsername = username;
    }
    getTempUsername() {
        return this.tempUsername;
    }
    saveUsername(username) {
        this.tempUsername = null;
        this.savedUsername = username;
    }
    getSavedUsername() {
        return this.savedUsername;
    }
    isInternalUser(username) {
        return !!this.getOktaConfig(username).samlConfig;
    }
    isLoggedInInternalUser() {
        return (this.userClaims &&
            this.userClaims.preferred_username &&
            !!this.getOktaConfig(this.userClaims.preferred_username).samlConfig);
    }
    doActivationHandoff(username, password, router) {
        this.activationHandoff = {
            username: username,
            password: password
        };
        // Expire the handoff if not consumed within a certain period
        let self = this;
        setTimeout(() => {
            self.activationHandoff = null;
        }, this.HANDOFF_TIMEOUT);
        this.saveTempUsername(username);
        router.navigate(['/signin']);
    }
    hasPendingActivationHandoff() {
        return this.activationHandoff && this.activationHandoff.password;
    }
    getActivationHandoffPassword() {
        let password = this.activationHandoff.password;
        this.activationHandoff = null;
        return password;
    }
    getClaimsSubscription() {
        // TODO: this might not update on token refresh
        return this.claimsSubject.asObservable();
    }
    /**
     *
     * @returns {string} the admin url string if config has admin url and user is in admin group,
     * undefined otherwise
     */
    getAdminUrl() {
        if (this.defaultOktaConfig.adminGroup &&
            this.defaultOktaConfig.adminPath &&
            this.userClaims.groups &&
            this.userClaims.groups.find(group => group === this.defaultOktaConfig.adminGroup)) {
            return this.defaultOktaConfig.baseUrl + this.defaultOktaConfig.adminPath;
        }
        else {
            return null;
        }
    }
}
__decorate([
    LocalStorage(),
    __metadata("design:type", String)
], AuthStateService.prototype, "savedUsername", void 0);
__decorate([
    SessionStorage(),
    __metadata("design:type", String)
], AuthStateService.prototype, "tempReturnUrl", void 0);
__decorate([
    SessionStorage(),
    __metadata("design:type", String)
], AuthStateService.prototype, "tempUsername", void 0);
__decorate([
    SessionStorage(),
    __metadata("design:type", Object)
], AuthStateService.prototype, "redirectCounter", void 0);
__decorate([
    SessionStorage(),
    __metadata("design:type", Object)
], AuthStateService.prototype, "redirectLastTimestamp", void 0);
