export enum AlertSeverity {
    DANGER = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info'
}

export interface Alert {
    severity: AlertSeverity;
    message: string;
    id: number;
    application?: string;
    wide?: boolean;
    dismissButton?: boolean;
}
