<div ngbDropdown class="d-inline-block app-selector">
    <a class="list-profile-toggle" ngbDropdownToggle (click)="onOpen()">
        <span
            class="axis-sprite axis-sprite-apps"
            title="Application Selector"
            id="ga-app-selector"
        ></span>
    </a>
    <div ngbDropdownMenu>
        <a class="dropdown-item" *ngFor="let app of apps" href="{{ app.url }}">
            <img *ngIf="app.iconSmall" src="{{ app.iconSmall }}" width="18" />
            {{ app.name }}
        </a>
    </div>
</div>
