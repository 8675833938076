/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-name.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../../layouts/layout-public/layout-public.component";
import * as i4 from "./login-name-form/login-name-form.component.ngfactory";
import * as i5 from "./login-name-form/login-name-form.component";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/services/auth-state.service";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/common";
import * as i10 from "./login-name.component";
import * as i11 from "../../shared/services/app-config.service";
import * as i12 from "../../shared/services/app-alert.service";
var styles_LoginNameComponent = [i0.styles];
var RenderType_LoginNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginNameComponent, data: {} });
export { RenderType_LoginNameComponent as RenderType_LoginNameComponent };
export function View_LoginNameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 13, "div", [["class", "splash-card-wrapper-thin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "splash-card-header w-100 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "splash-card-header-logo"], ["src", "/assets/form_q_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "splash-card-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign In"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-login-name-form", [], null, null, null, i4.View_LoginNameFormComponent_0, i4.RenderType_LoginNameFormComponent)), i1.ɵdid(8, 114688, null, 0, i5.LoginNameFormComponent, [i6.Router, i1.NgZone, i7.AuthStateService, i8.FormBuilder], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "hr-stike"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" OR "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "w-100 btn btn-success"], ["id", "requestAccountBtn"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, [" Request An Account "]))], function (_ck, _v) { _ck(_v, 8, 0); var currVal_2 = _ck(_v, 14, 0, "/signup"); _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 13).target; var currVal_1 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_0, currVal_1); }); }
export function View_LoginNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-name", [], null, null, null, View_LoginNameComponent_0, RenderType_LoginNameComponent)), i1.ɵdid(1, 49152, null, 0, i10.LoginNameComponent, [i11.AppConfigService, i12.AppAlertService], null, null)], null, null); }
var LoginNameComponentNgFactory = i1.ɵccf("app-login-name", i10.LoginNameComponent, View_LoginNameComponent_Host_0, {}, {}, []);
export { LoginNameComponentNgFactory as LoginNameComponentNgFactory };
