/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alert.component.ngfactory";
import * as i3 from "./alert.component";
import * as i4 from "./app-alert.component";
import * as i5 from "../../services/app-alert.service";
var styles_AppAlertComponent = [i0.styles];
var RenderType_AppAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppAlertComponent, data: {} });
export { RenderType_AppAlertComponent as RenderType_AppAlertComponent };
export function View_AppAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qb-alert", [], null, [[null, "onAlertClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAlertClose" === en)) {
        var pd_0 = (_co.handleAlertClosed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 49152, null, 0, i3.AlertComponent, [], { alert: [0, "alert"] }, { onAlertClose: "onAlertClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentAlert; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qb-app-alert", [], null, null, null, View_AppAlertComponent_0, RenderType_AppAlertComponent)), i1.ɵdid(1, 49152, null, 0, i4.AppAlertComponent, [i5.AppAlertService], null, null)], null, null); }
var AppAlertComponentNgFactory = i1.ɵccf("qb-app-alert", i4.AppAlertComponent, View_AppAlertComponent_Host_0, {}, {}, []);
export { AppAlertComponentNgFactory as AppAlertComponentNgFactory };
