import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SupportService } from '../support.service';
import {
    Ticket,
    TicketAttachment,
    TicketComment
} from '../../../../common/models/ticket.interface';

@Component({
    selector: 'app-view-ticket',
    styleUrls: ['view-ticket.component.sass'],
    templateUrl: './view-ticket.component.html'
})
export class ViewTicketComponent implements OnInit {
    ticket: Ticket;
    newComment;
    error: any;
    inProgress = false;
    spinner = false;
    attachment = {
        isUploading: false,
        success: false,
        selectedFile: ''
    };

    constructor(private route: ActivatedRoute, private supportService: SupportService) {}

    ngOnInit(): void {
        this.getTicket();
    }

    getTicket() {
        this.inProgress = true;
        this.route.params
            .pipe(switchMap(data => this.supportService.getTicket(data.id)))
            .subscribe((data: Ticket) => {
                this.inProgress = false;
                this.ticket = data;
            });
    }

    addComment(commentForm) {
        this.spinner = true;
        this.supportService
            .addComment(this.ticket.key, commentForm.value)
            .subscribe((comments: TicketComment[]) => {
                this.spinner = false;
                commentForm.reset();
                this.ticket.comments = comments;
            });
    }

    onAttachmentChange(event) {
        this.attachment.isUploading = true;
        this.attachment.success = false;
        this.attachment.selectedFile = event.target.files[0].name;
        this.supportService
            .addAttachment({
                issueId: this.ticket.key,
                attachment: event.target.files[0]
            })
            .subscribe(
                (data: TicketAttachment) => {
                    this.attachment.isUploading = false;
                    this.attachment.success = true;
                    this.ticket.attachments.unshift(data);
                },
                error => {
                    this.attachment.isUploading = false;
                }
            );
    }

    isTicketClosed() {
        if (this.ticket && this.ticket.status) {
            return this.ticket.status === 'Closed';
        }
        return false;
    }
}
