import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AuthStateService } from './auth-state.service';
import { Alert, AlertSeverity } from '../../../../common/models/alert.interface';
import { AppConfigService } from './app-config.service';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { AppConfig, TermsCookieState } from '../../../../common/models/app-config.interface';
import { isObject } from 'lodash-es';

let nextId = 10; // first 10 reserved for system alerts.
export class AppAlert implements Alert {
    public id: number;
    public application: string;

    constructor(public severity: AlertSeverity, public message: string) {
        this.id = nextId++;
    }

    static create(severity: string, message: string): AppAlert {
        let alertSeverity: AlertSeverity = AlertSeverity[severity];
        if (!alertSeverity) {
            alertSeverity = AlertSeverity.DANGER;
        }
        return new AppAlert(alertSeverity, message);
    }
}

@Injectable()
export class AppAlertService {
    private alertQueue: Alert[] = [];
    private alertStream: ReplaySubject<Alert>;
    private MAX_ALERTS = 2;
    private termsAlert: Alert = null;
    private termsCookieState: TermsCookieState = {
        lastDismissed: null,
        lastViewed: null,
        startDate: null,
        endDate: null
    };
    private appConfig: AppConfig = null;
    private currentAlert: Alert = null;

    constructor(
        private authStateService: AuthStateService,
        private appConfigService: AppConfigService,
        private cookieService: CookieService
    ) {
        this.alertStream = new ReplaySubject<Alert>(this.MAX_ALERTS);
        this.authStateService.getClaimsSubscription().subscribe(claims => {
            if (claims) {
                // flush alerts on successful logins
                this.flush();
                if (this.termsAlert) {
                    this.queueAlert(this.termsAlert);
                }
            }
        });

        appConfigService.config.subscribe((config: AppConfig) => {
            this.appConfig = config;
            let termsCookieState: TermsCookieState = this.getTermsCookieState();
            if (
                !isObject(termsCookieState) ||
                termsCookieState.startDate != config.termsConfig.startDate ||
                termsCookieState.endDate != config.termsConfig.endDate
            ) {
                // Reset cookie if invalid or out of date
                this.setTermsStateCookie({
                    lastDismissed: null,
                    lastViewed: null,
                    startDate: this.appConfig.termsConfig.startDate,
                    endDate: this.appConfig.termsConfig.endDate
                });
            } else {
                this.termsCookieState = termsCookieState;
            }

            this.termsAlert = this.buildTermsAlert();
            if (this.termsAlert) {
                this.termsAlert.dismissButton = true;
                this.queueAlert(this.termsAlert);
            }
        });

        this.alertStream.pipe(filter(alert => alert != null)).subscribe(alert => {
            this.currentAlert = alert;
        });
    }

    public queueAlert(alert: Alert) {
        this.alertQueue.push(alert);

        // If this is the only alert in queue then emit immediately.
        if (!this.currentAlert) {
            this.next();
        }
    }

    public getCurrentAlert(): Alert {
        return this.currentAlert;
    }

    public close(alert: Alert) {
        if (alert == this.termsAlert) {
            // If closed alert is terms alert then mark dismissed.
            this.setTermsStateCookie({
                lastDismissed: Date.now()
            });
        }

        this.next();
    }

    private next() {
        this.currentAlert = null;

        // If there are more alerts in the queue then emit, otherwise do nothing.
        if (this.alertQueue.length > 0) {
            const nextAlert = this.alertQueue.shift();
            this.alertStream.next(nextAlert);

            if (nextAlert == this.termsAlert) {
                // If new alert is terms alert then mark viewed.
                this.setTermsStateCookie({
                    lastViewed: Date.now()
                });
            }
        }
    }

    public flush() {
        this.currentAlert = null;
        this.alertQueue = [];
        this.alertStream.next(null);
    }

    private getTermsCookieState(): TermsCookieState {
        let termsCookieConfig: TermsCookieState = null;
        try {
            termsCookieConfig = JSON.parse(
                this.cookieService.get(this.appConfig.termsConfig.cookieName)
            );
        } catch (err) {
            termsCookieConfig = null;
        }
        return termsCookieConfig;
    }

    private buildTermsAlert(): Alert {
        const now = Date.now();
        const dismissed = this.termsCookieState.lastDismissed;
        const termsConfig = this.appConfig.termsConfig;

        return now >= termsConfig.startDate &&
            now <= termsConfig.endDate &&
            (typeof dismissed !== 'number' ||
                !isFinite(dismissed) ||
                dismissed < termsConfig.startDate)
            ? new AppAlert(AlertSeverity.INFO, termsConfig.template)
            : null;
    }

    private setTermsStateCookie(state: Partial<TermsCookieState>) {
        Object.assign(this.termsCookieState, state);
        this.cookieService.put(
            this.appConfig.termsConfig.cookieName,
            JSON.stringify(this.termsCookieState),
            {
                expires: new Date(this.appConfig.termsConfig.expiresDate),
                domain: this.appConfig.ssoDomain
            }
        );
    }
}
