import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../services/auth-state.service';

@Injectable()
export class LoginParametersGuard implements CanActivate {
    constructor(private router: Router, private authStateService: AuthStateService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // Always save return URL if provided
        this.authStateService.saveReturnUrl(next);

        // if username= is empty url paramenter, then proceed to login form and clear saved values
        if (next.queryParams['username'] === '') {
            this.authStateService.saveTempUsername(null);
            this.authStateService.saveUsername(null);
            return true;
        } else if (next.queryParams['username']) {
            // If a username is provided in query params, override the saved username
            this.authStateService.saveTempUsername(next.queryParams['username']);
            this.authStateService.saveUsername(null);
        }

        // if username found in the storage, move directly to signin
        if (this.authStateService.getTempUsername() || this.authStateService.getSavedUsername()) {
            this.router.navigate(['/signin']);
            return false;
        }

        // else activate the route
        return true;
    }
}
