import { throwError as observableThrowError, Observable, Subscription, ReplaySubject } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Application } from '../../../../common/models/application.interface';
import { ApiResponse } from '../../../../common/models/api-response.interface';
import { APP_SETTINGS } from '../../app-settings';
import { AuthStateService } from './auth-state.service';

@Injectable()
export class AppListService {
    private status: string;
    private applicationSource: ReplaySubject<Application[]>;
    private requestSubscription: Subscription;

    constructor(
        private http: HttpClient,
        @Inject(APP_SETTINGS) private appSettings,
        private authStateService: AuthStateService
    ) {
        this.status = this.appSettings.NOT_LOADED;
        this.applicationSource = new ReplaySubject(1);
        this.authStateService.getClaimsSubscription().subscribe(claims => {
            if (claims) {
                // refresh app list when claims change.
                this.refresh();
            }
        });
    }

    public get applications(): Observable<Application[]> {
        if (this.status === this.appSettings.NOT_LOADED) {
            this.refresh();
        }
        return this.applicationSource.asObservable();
    }

    public refresh() {
        if (this.requestSubscription && !this.requestSubscription.closed) {
            this.requestSubscription.unsubscribe();
        }

        this.status = this.appSettings.LOADING;
        this.requestSubscription = this.http
            .get<ApiResponse<Application[]>>(this.appSettings.apiEndpoint + 'applications')
            .pipe(
                map(res => {
                    this.status = this.appSettings.LOADED;
                    this.applicationSource.next(res.data);
                }),
                catchError(error => {
                    this.status = this.appSettings.NOT_LOADED;
                    let errMsg = error.message
                        ? error.message
                        : error.status
                        ? `${error.status} - ${error.statusText}`
                        : 'Server error loading available apps';
                    console.error(errMsg);
                    return observableThrowError(errMsg);
                })
            )
            .subscribe();
    }
}
