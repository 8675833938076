import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Topic } from '../../../../common/models/help.interface';
import { AppListService } from '../../shared/services/app-list.service';
import { HelpTopicService } from '../help-topic/help-topic.service';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.sass']
})
export class HelpComponent implements OnInit, OnDestroy {
    private subTopic: Subscription;
    private subApp: Subscription;
    helpLinks;
    error: any;
    topics: Topic[];
    hero = {
        title: 'Welcome to Quotient Business Help',
        lead: ''
    };

    constructor(
        private router: Router,
        private service: HelpTopicService,
        private appListService: AppListService,
        private angulartics: Angulartics2
    ) {}

    ngOnInit() {
        this.getTopics();
        this.getHelplinks();
    }

    getTopics() {
        this.subTopic = this.service.topics.subscribe(helpTopics => {
            this.topics = this.service.toTopics(helpTopics);
        });
    }

    // TODO: move this logic to help.service.ts
    getHelplinks() {
        this.subApp = this.appListService.applications.subscribe(apps => {
            this.helpLinks = apps
                .filter(app => app.urlHelp && app.enabled)
                .map(app => {
                    return {
                        url: app.urlHelp,
                        cssClass: `ico-${app.access_key}`,
                        caption: app.name,
                        icon: app.iconLarge
                    };
                });
        });
    }

    onTopicSelect(topic) {
        this.angulartics.eventTrack.next({
            action: 'view-help-topic',
            properties: {
                category: 'Support',
                label: 'help-id-' + topic.id
            }
        });
        this.router.navigate(['support', 'help', topic.id]);
    }

    ngOnDestroy() {
        this.subTopic.unsubscribe();
        this.subApp.unsubscribe();
    }
}
