import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-public',
    templateUrl: './layout-public.component.html',
    styleUrls: ['./layout-public.component.sass'],
    inputs: ['isTos']
})
export class LayoutPublicComponent {
    isTos: boolean;
}
