import { SignupUser } from '../../../../common/models/signup-user.interface';

export class SignupForm implements SignupUser {
    constructor(
        public firstName = '',
        public lastName = '',
        public email = '',
        public companyName = '',
        public captcha = ''
    ) {}
}
