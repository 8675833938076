import {
    throwError as observableThrowError,
    of as observableOf,
    from as observableFrom,
    Observable
} from 'rxjs';

import { switchMap, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';

import { AuthStateService } from './auth-state.service';
import { AppAlert, AppAlertService } from './app-alert.service';
import { AlertSeverity } from '../../../../common/models/alert.interface';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    authStateService: AuthStateService;
    private ignoreIntercept: Array<string> = [
        'https://quotient-customer.oktapreview.com/api/v1/authn',
        'https://quotient-customer.oktapreview.com/api/v1/authn/credentials/reset_password',
        'https://quotient-customer.okta.com/api/v1/authn',
        'https://quotient-customer.okta.com/api/v1/authn/credentials/reset_password'
    ];

    constructor(
        private injector: Injector,
        private router: Router,
        private appAlertService: AppAlertService
    ) {
        this.authStateService = this.injector.get(AuthStateService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.ignoreIntercept.indexOf(req.url) > -1) {
            // possible error codes on this event E0000004, E0000011
            // https://developer.okta.com/reference/error_codes/
            return next.handle(req);
        }

        return next.handle(this.setRequestAuthHeader(req)).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    return observableFrom(this.authStateService.refreshAuthToken()).pipe(
                        switchMap(() => {
                            if (!this.authStateService.getUserClaims()) {
                                this.authStateService.handle401Redirect(this.router);
                            } else {
                                return next.handle(this.setRequestAuthHeader(req)).pipe(
                                    catchError((err2: any) => {
                                        this.authStateService.handle401Redirect(this.router);
                                        return observableOf(err2);
                                    })
                                );
                            }
                        })
                    );
                } else if (err instanceof HttpErrorResponse && err.status === 409) {
                    // Validation error messaging, should be handled in services
                    return observableThrowError(err);
                } else {
                    let alertMessage =
                        'A data request failed, please refresh the page, or try signing in again.';
                    if (err.error && err.error.displayToUser === true) {
                        alertMessage = err.error.message;
                    }
                    this.appAlertService.queueAlert(
                        new AppAlert(AlertSeverity.DANGER, alertMessage)
                    );
                    throw err;
                }
            })
        );
    }

    setRequestAuthHeader(req: HttpRequest<any>) {
        let processedReq: HttpRequest<any> = req;
        if (
            this.authStateService &&
            this.authStateService.isReady &&
            (this.authStateService.isAuthenticated || this.authStateService.hasOnlyTempToken())
        ) {
            let bearerToken = this.authStateService.idToken;
            if (this.authStateService.hasOnlyTempToken()) {
                bearerToken = this.authStateService.tempToken;
            }
            processedReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${bearerToken}`)
            });
        }
        return processedReq;
    }
}
