import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SubmitResult } from '../models/support.interface';
import {
    CreateTicketPicklist,
    TicketPicklist
} from '../../../../common/models/support-picklist.interface';

import { SupportService } from '../support.service';
import { ListTicketService } from '../list-tickets/list-ticket.service';
import { CreateTicketPicklistService } from './create-ticket-picklist.service';
import { SupportTicket } from '../models/support-ticket';
import { AuthStateService } from '../../shared/services/auth-state.service';

@Component({
    selector: 'app-support',
    styleUrls: ['./create-ticket.component.sass'],
    templateUrl: './create-ticket.component.html'
})
export class CreateTicketComponent implements OnInit, OnDestroy {
    apps: CreateTicketPicklist[];
    private sub;
    ticket: SupportTicket;
    formSubmit: SubmitResult;
    error: any;
    inProgress = false;
    isSubmitted = false;
    isInternalAccessRequest = false;

    hero = {
        title: 'Welcome to Quotient Business Support',
        lead: 'Fill out each field accordingly. We will respond within a business day.'
    };

    constructor(
        private router: Router,
        private supportService: SupportService,
        private activatedRoute: ActivatedRoute,
        private ticketService: ListTicketService,
        private picklistService: CreateTicketPicklistService,
        private authStateService: AuthStateService
    ) {}

    ngOnInit() {
        this.ticket = new SupportTicket();
        let queryParams: object;
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            queryParams = params;
        });

        this.picklistService.ticketPicklist.subscribe((data: CreateTicketPicklist[]) => {
            this.apps = data;
            if (queryParams) {
                this.prefillFormFields(data, queryParams);
            }
        });
    }

    onAppChange() {
        this.ticket.selectedTicketType = '';
        this.ticket.selectedReason = '';
    }

    onTicketTypeChange() {
        this.ticket.selectedReason = '';
    }

    /**
     * @param apps - create ticket form picklist
     * @param params - ?app=axis&ticket_type=coupon_creation&reason=io&url=http:%2F%2Fcoupons.com
     */
    prefillFormFields(apps, params) {
        if (apps && params.app) {
            this.ticket.selectedApp = apps.filter(app => app.accessKey === params.app)[0];
        }
        if (params.ticket_type) {
            this.ticket.selectedTicketType = this.ticket.selectedApp.ticketTypes.filter(
                ticketType => ticketType.key === params.ticket_type
            )[0];

            if (
                this.ticket.selectedTicketType &&
                this.ticket.selectedTicketType.key === 'app_access' &&
                this.authStateService.isLoggedInInternalUser()
            ) {
                this.isInternalAccessRequest = true;
            }
        }
        if (params.reason) {
            this.ticket.selectedReason = this.ticket.selectedTicketType.ticketReasons.filter(
                reason => reason.key === params.reason
            )[0];
        }
        if (params.custom_description) {
            this.ticket.description = params.custom_description;
        }
        if (params.url) {
            this.ticket.description = `${this.ticket.description}\nApp url: ${params.url}`;
        }
    }

    createTicket(createTicketForm) {
        if (createTicketForm.valid) {
            this.inProgress = true;
            this.supportService.createTicket(this.ticket.toPayload()).subscribe(response => {
                this.inProgress = false;
                this.isSubmitted = true;

                // createTicketForm.reset();
                this.formSubmit = {
                    isSuccess: true,
                    message: response.key
                };
            });
        }
    }

    gotoTicket(key) {
        this.router.navigate(['/support/tickets', key]);
    }

    cancel() {
        if (this.hasTickets()) {
            this.router.navigate(['/support/tickets']);
        } else {
            this.router.navigate(['/']);
        }
    }

    onCreateNew() {
        this.isSubmitted = false;
        this.ticket = new SupportTicket();
    }

    hasTickets(): boolean {
        let hasTickets;
        this.ticketService.jiraTickets.subscribe(data => (hasTickets = data.length > 0));
        return hasTickets;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
