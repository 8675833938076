import { Component, OnInit } from '@angular/core';
import { TosService } from '../tos.service';
import { Tos } from '../../../../common/entities/tos.model';

@Component({
    selector: 'app-tos-view',
    templateUrl: './tos-view.component.html',
    styleUrls: ['./tos-view.component.sass']
})
export class TosViewComponent implements OnInit {
    tos: Tos;
    publishDateStr = '';
    errMsg = '';

    constructor(private tosService: TosService) {}

    ngOnInit() {
        this.tosService.getTos().subscribe(
            tos => {
                this.tos = tos;
                const pubDate = new Date(this.tos.publishDate);
                const [date, time] = pubDate.toLocaleString('en-US').split(', ');
                this.publishDateStr = date;
            },
            error => (this.errMsg = 'Failed to find ToS version. ' + error)
        );
    }
}
