import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(gtm: Angulartics2GoogleTagManager) {}

    ngOnInit() {
        console.log(environment.envName);
    }
}
