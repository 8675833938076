import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from './auth-state.service';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { Okta } from '../../../../common/models/okta.interface';

@Injectable()
export class OktaAuthService {
    signIn: any = {};
    oktaConfig: Okta.Config;
    username: string;
    errorMessage: any;

    constructor(
        private router: Router,
        private zone: NgZone,
        private authStateService: AuthStateService
    ) {}

    login(userName: string) {
        let oktaConfig: Okta.Config = this.authStateService.getOktaConfig(userName);
        this.oktaConfig = oktaConfig;
        oktaConfig.widgetConfig.username = userName;

        if (this.signIn.session) {
            this.signIn.remove();
        }

        this.signIn = new OktaSignIn(oktaConfig.widgetConfig);

        // Launches the widget and stores the tokens.
        this.signIn.renderEl(
            { el: '#okta-login-container' },
            response => {
                if (response.status === 'SUCCESS') {
                    if (oktaConfig.samlConfig) {
                        // If SAML config exists do IDP flow
                        response.session.setCookieAndRedirect(
                            `${oktaConfig.samlConfig.ssoUrl}?${
                                oktaConfig.samlConfig.relayStateParam
                            }=${encodeURIComponent(oktaConfig.samlConfig.relayStateValue)}`
                        );
                    } else {
                        // External user authorization flow
                        this.authStateService.setTempToken(response.idToken);
                        this.zone.run(() => this.router.navigate(['/home']));
                    }
                }
                // do nothing if response.status is different from 'SUCCESS'
                // we don't process 'FORGOT_PASSWORD_EMAIL_SENT' or 'UNLOCK_ACCOUNT_EMAIL_SENT' here
            },
            error => {
                // The widget will handle most types of errors - for example, if the user
                // enters an invalid password or there are issues authenticating.
                //
                // This function is invoked with errors the widget cannot recover from:
                // 1. Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR, OAUTH_ERROR
                // 2. Uncaught exceptions
                console.log('renderEl error handler. ' + error.message);
            }
        );

        this.signIn.on('afterRender', data => {
            // hack okta widget after its initialization
            if (data.page === 'forgot-password') {
                this.prefillForgotPasswordUsername();
            } else {
                this.hackWidgetAfterRendering();
            }
        });
    }

    /**
     * Event constructor polyfill for IE11
     * @param {string} name event name to create
     * @param {boolean} bubbles event bubbles property
     * @param {boolean} cancelable event cancelable property
     * @returns {Event} the created event instance
     */
    createEvent(name: string, bubbles: boolean, cancelable: boolean): Event {
        let event = null;
        if (typeof Event === 'function') {
            // Evergreen browsers have Event constructor
            event = new Event(name, { bubbles: bubbles, cancelable: cancelable });
        } else {
            // IE11 and below have no Event constructor, use polyfill method
            event = document.createEvent('Event');
            event.initEvent(name, bubbles, cancelable);
        }
        return event;
    }

    prefillForgotPasswordUsername() {
        // TODO: This stuff might belong in a component
        let usernameEmailElement: HTMLInputElement = <HTMLInputElement>(
            document.querySelector('input[name=username]')
        );
        if (usernameEmailElement && this.oktaConfig.widgetConfig.username) {
            usernameEmailElement.value = this.oktaConfig.widgetConfig.username;
            // Okta widget uses angular too so we need to force an even to allow it to consume
            // the pre-filled password before queuing up a signin click.
            usernameEmailElement.dispatchEvent(this.createEvent('input', true, false));
        }
    }
    /**
     * 1. make username field read-only
     * 2. set href on the custom 'button' (see how it is converted to pure link in css file)
     * 3. move this custom link/button under username text input
     */
    hackWidgetAfterRendering() {
        // TODO: This stuff might belong in a component
        let userNameElement = document.getElementById('okta-signin-username');
        if (!userNameElement) {
            return;
        }
        userNameElement['readOnly'] = true;

        document.getElementById('okta-signin-password').focus();

        let changeLinkElement: HTMLElement = <HTMLElement>(
            document.querySelector('a.default-custom-button')
        );

        // Fallback in case click handling is broken
        changeLinkElement['href'] = '/login?username= ';
        // capture click to navigate w/o page reload
        changeLinkElement.addEventListener(
            'click',
            e => {
                e.preventDefault();
                this.zone.run(() =>
                    this.router.navigate(['/login'], { queryParams: { username: '' } })
                );
                return false;
            },
            false
        );

        let userNameContainer = userNameElement.parentElement.parentElement;
        userNameContainer.style.position = 'relative';
        userNameContainer.appendChild(changeLinkElement);

        if (this.authStateService.hasPendingActivationHandoff()) {
            let signIn: HTMLElement = <HTMLElement>(
                document.querySelector('input#okta-signin-submit')
            );
            let passwordElement: HTMLInputElement = <HTMLInputElement>(
                document.getElementById('okta-signin-password')
            );
            if (signIn && passwordElement) {
                passwordElement.value = this.authStateService.getActivationHandoffPassword();
                // Okta widget uses angular too so we need to force an even to allow it to consume
                // the pre-filled password before queuing up a signin click.
                passwordElement.dispatchEvent(this.createEvent('input', true, false));
                signIn.click();
            }
        }
    }

    resetPassword(token: string) {
        let oktaConfig = this.authStateService.getOktaConfig();
        oktaConfig.widgetConfig.recoveryToken = token;
        this.signIn = new OktaSignIn(oktaConfig.widgetConfig);

        this.signIn.renderEl(
            { el: '#okta-reset-password-container' },
            response => {
                if (response.status === 'SUCCESS') {
                    if (response.idToken) {
                        this.authStateService.setTempToken(response.idToken);
                    }

                    this.zone.run(() => this.router.navigate(['/home']));
                }
            },
            error => {
                console.log('renderEl error handler for reset password. ' + error.message);
            }
        );
        this.signIn.on('pageRendered', data => {
            // hack okta widget after its initialization
            if (data.page === 'forgot-password') {
                return;
            }
            this.hackWidgetAfterRendering();
        });
    }

    logout() {
        this.authStateService.logout();
    }
}
