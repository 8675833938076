/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-public.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header-bare/header-bare.component.ngfactory";
import * as i3 from "../header/header-bare/header-bare.component";
import * as i4 from "../../shared/components/alert/app-alert.component.ngfactory";
import * as i5 from "../../shared/components/alert/app-alert.component";
import * as i6 from "../../shared/services/app-alert.service";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "../../shared/services/app-config.service";
import * as i10 from "./layout-public.component";
var styles_LayoutPublicComponent = [i0.styles];
var RenderType_LayoutPublicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutPublicComponent, data: {} });
export { RenderType_LayoutPublicComponent as RenderType_LayoutPublicComponent };
export function View_LayoutPublicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "splash-bg-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "splash-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "splash-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "splash-img-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-header-bare", [], null, null, null, i2.View_HeaderBareComponent_0, i2.RenderType_HeaderBareComponent)), i1.ɵdid(5, 49152, null, 0, i3.HeaderBareComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "qb-app-alert", [], null, null, null, i4.View_AppAlertComponent_0, i4.RenderType_AppAlertComponent)), i1.ɵdid(7, 49152, null, 0, i5.AppAlertComponent, [i6.AppAlertService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "splash-body container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "splash-card mx-auto"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(13, 49152, null, 0, i8.FooterComponent, [i9.AppConfigService], { isLight: [0, "isLight"], isTos: [1, "isTos"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.isTos; _ck(_v, 13, 0, currVal_0, currVal_1); }, null); }
export function View_LayoutPublicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-public", [], null, null, null, View_LayoutPublicComponent_0, RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i10.LayoutPublicComponent, [], null, null)], null, null); }
var LayoutPublicComponentNgFactory = i1.ɵccf("app-layout-public", i10.LayoutPublicComponent, View_LayoutPublicComponent_Host_0, { isTos: "isTos" }, {}, ["*"]);
export { LayoutPublicComponentNgFactory as LayoutPublicComponentNgFactory };
