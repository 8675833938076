import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppAlert, AppAlertService } from '../shared/services/app-alert.service';
import { AppListService } from '../shared/services/app-list.service';

import { Alert } from '../../../common/models/alert.interface';
import { AuthErrorType } from '../../../common/models/auth-api.interface';

@Component({
    selector: 'ExternalErrorComponent',
    template: ' '
})
export class ExternalErrorComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appAlertService: AppAlertService,
        private appListService: AppListService
    ) {}

    private alertCodes: { [key in AuthErrorType]: Alert } = {
        expired: AppAlert.create(
            'warning',
            'Your session has expired, please log in again to continue.'
        ),
        unauthenticated: AppAlert.create(
            'danger',
            'Authentication failed, please log in again to continue.'
        ),
        unauthorized: AppAlert.create(
            'danger',
            'Insufficient access, please click "Request Access" below or contact support.'
        ),

        unknown: AppAlert.create(
            'danger',
            'Error loading application, please try again or contact support if the problem persists.'
        ),
        logout_required: AppAlert.create(
            'warning',
            'This action can only be performed while logged out.'
        ),
        throttled: AppAlert.create(
            'danger',
            'Unable to load application, please try again later or contact support if the problem persists.'
        )
    };

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            let alert: Alert = this.alertCodes[params.get('code')];
            if (!alert) {
                alert = this.alertCodes['unknown'];
            }
            if (params.get('application')) {
                this.appListService.applications.pipe(take(1)).subscribe(apps => {
                    let matches = apps.filter(
                        app =>
                            app.access_key.toLowerCase() === params.get('application').toLowerCase()
                    );
                    if (matches && matches.length === 1) {
                        alert.application = matches[0].name;
                    }
                    this.appAlertService.queueAlert(alert);
                });
            } else {
                this.appAlertService.queueAlert(alert);
            }
        });

        this.router.navigate(['/home']);
    }
}
