<div ngbDropdown class="d-inline-block user-menu" placement="bottom-right">
    <a class="list-profile-toggle" id="dropdownUserMenu" ngbDropdownToggle>
        <span class="axis-sprite axis-sprite-user" title="{{ userName }}"></span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownUserMenu">
        <div class="dropdown-menu-username">{{ userName }}</div>
        <a class="dropdown-item" *ngIf="adminUrl" href="{{ adminUrl }}">
            Admin Console
        </a>
        <a class="dropdown-item" [routerLink]="['/profile']">
            My Profile
        </a>
        <a class="dropdown-item" [routerLink]="['/logout']">
            Sign Off
        </a>
    </div>
</div>
