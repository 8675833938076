import { Component, Input, OnInit } from '@angular/core';
import { AppListService } from '../shared/services/app-list.service';
import { Application } from '../../../common/models/application.interface';
import { ActivatedRoute } from '@angular/router';
import { Alert, AlertSeverity } from '../../../common/models/alert.interface';
import { AppAlert, AppAlertService } from '../shared/services/app-alert.service';
import { Location } from '@angular/common';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
    apps: Application[];
    errorMessage: any;

    @Input()
    hero = {
        title: 'Welcome to Quotient Business',
        lead: ''
    };

    constructor(
        private appListService: AppListService,
        private location: Location,
        private route: ActivatedRoute,
        private appAlertService: AppAlertService
    ) {}

    ngOnInit() {
        this.appListService.applications.subscribe(
            apps => (this.apps = apps.filter(app => !app.home)),
            error => (this.errorMessage = <any>error)
        );

        // Zendesk error handling
        if (this.route.snapshot.queryParams['kind'] === 'error') {
            const alert: Alert = AppAlert.create(
                AlertSeverity.DANGER,
                this.route.snapshot.queryParams['message'] || 'Something went wrong'
            );
            this.appAlertService.queueAlert(alert);
            // navigating to home to clear query params and to replace history PAIW-4470
            this.location.replaceState('home');
        }
    }
}
