import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-secure',
    templateUrl: './layout-secure.component.html',
    styleUrls: ['./layout-secure.component.sass']
})
export class LayoutSecureComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
