/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../../layouts/layout-public/layout-public.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "splash-card-header w-100 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "splash-card-header-logo"], ["src", "/assets/form_q_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "splash-card-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! That page couldn't be found."])), (_l()(), i1.ɵeld(6, 0, null, 0, 6, "div", [["class", "splash-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please head back to "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["the homepage"])), (_l()(), i1.ɵted(-1, null, [", and use one of the application cards to navigate. "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 10, 0, "/home"); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i6.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i6.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
