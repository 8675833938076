import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '../../../shared/services/auth-state.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.sass']
})
export class UserMenuComponent implements OnInit {
    userName: string;
    userAvatarUrl: string;
    adminUrl: string;

    constructor(private authStateService: AuthStateService, private router: Router) {}

    ngOnInit() {
        this.userName = this.authStateService.getUserClaims().name;
        this.userAvatarUrl =
            this.authStateService.getUserClaims().user_image || '/assets/avatar_default.png';
        this.adminUrl = this.authStateService.getAdminUrl();
    }
}
