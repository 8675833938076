<footer class="footer" [class.light]="isLight" on>
    <hr class="footer-hr" *ngIf="!isLight" />
    <div class="footer-links">
        <p class="small">
            <span>&copy; {{ currentYear }} Quotient Technology Inc. All Rights Reserved.</span>
            |<a target="_blank" href="//www.quotient.com/privacy-policy/">Privacy Statement</a>
            <span *ngIf="!isTos"> |<a target="_new" href="/tos">Terms of Use</a> </span>
            <span *ngIf="!isLight"> | v{{ version }} </span>
        </p>
    </div>
</footer>
