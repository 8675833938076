/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-box.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./help-box.component";
var styles_HelpBoxComponent = [i0.styles];
var RenderType_HelpBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpBoxComponent, data: {} });
export { RenderType_HelpBoxComponent as RenderType_HelpBoxComponent };
export function View_HelpBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "dash-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dash-card-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "ico-help"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Help Center "])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "dash-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Visit the "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["Help Center"])), (_l()(), i1.ɵted(-1, null, [" for any questions or concerns. User Guides, FAQs, help with profile customization, or help contacting Quotient can all be found in our "])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, ["Help Center"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "dash-card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "view-all-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "a", [["href", "/support/help"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View All Help"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 10, 0, "/support/help/"); _ck(_v, 9, 0, currVal_2); var currVal_5 = _ck(_v, 15, 0, "/support/help/"); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 14).target; var currVal_4 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); }); }
export function View_HelpBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-box", [], null, null, null, View_HelpBoxComponent_0, RenderType_HelpBoxComponent)), i1.ɵdid(1, 114688, null, 0, i4.HelpBoxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpBoxComponentNgFactory = i1.ɵccf("app-help-box", i4.HelpBoxComponent, View_HelpBoxComponent_Host_0, {}, {}, []);
export { HelpBoxComponentNgFactory as HelpBoxComponentNgFactory };
