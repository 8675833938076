import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStateService } from '../services/auth-state.service';

@Injectable()
export class AuthTempGuard implements CanActivate {
    constructor(private authStateService: AuthStateService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authStateService.hasOnlyTempToken()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
