<div class="row">
    <main class="col">
        <div [hidden]="!submitted">
            <h3>Token is submitted!</h3>
            <p>
                <a [routerLink]="['/']">Go Home</a> |
                <a [routerLink]="['/support/tickets']">Ticket</a>
            </p>
        </div>
        <div [hidden]="submitted">
            <form #debugForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
                <div class="form-group">
                    <label for="token"> Token:</label>
                    <textarea
                        id="token"
                        class="form-control"
                        rows="5"
                        name="token"
                        required
                        [(ngModel)]="model.token"
                    >
                    </textarea>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary" [disabled]="!debugForm.valid">
                        Set Token
                    </button>
                </div>
            </form>
        </div>
    </main>
</div>
