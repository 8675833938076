/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../okta.widget.sass.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../../layouts/layout-public/layout-public.component";
import * as i4 from "./signin.component";
import * as i5 from "../../shared/services/okta-auth.service";
import * as i6 from "../../shared/services/auth-state.service";
var styles_SigninComponent = [i0.styles];
var RenderType_SigninComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SigninComponent, data: {} });
export { RenderType_SigninComponent as RenderType_SigninComponent };
export function View_SigninComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "splash-card-wrapper-thin"], ["id", "okta-login-container"]], null, null, null, null, null))], null, null); }
export function View_SigninComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signin", [], null, null, null, View_SigninComponent_0, RenderType_SigninComponent)), i1.ɵdid(1, 114688, null, 0, i4.SigninComponent, [i5.OktaAuthService, i6.AuthStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninComponentNgFactory = i1.ɵccf("app-signin", i4.SigninComponent, View_SigninComponent_Host_0, {}, {}, []);
export { SigninComponentNgFactory as SigninComponentNgFactory };
