// security guards & services
import { AuthStateService } from './shared/services/auth-state.service';
// data services
import { AppSettings } from './app-settings';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
export function authStateServiceFactory(authStateService) {
    return () => authStateService.init();
}
const ɵ0 = AppSettings;
export class AppModule {
    constructor(angulartics2GoogleAnalytics) {
        angulartics2GoogleAnalytics.startTracking();
    }
}
export { ɵ0 };
