import { Pipe, PipeTransform } from '@angular/core';

/**
 * usage -  filesize | filesize
 * usage with option -  filesize | filesize:'MB'
 */
@Pipe({
    name: 'filesize'
})
export class FilesizePipe implements PipeTransform {
    transform(size: number, extension: string = 'KB') {
        const unit = 1024;
        const newSize = size / unit;
        if (newSize >= unit) {
            return (newSize / unit).toFixed(2) + ' ' + 'MB';
        }

        return (size / unit).toFixed(2) + ' ' + extension;
    }
}
