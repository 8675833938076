import { throwError as observableThrowError, Observable, ReplaySubject, Subscription } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiResponse } from '../../../../common/models/api-response.interface';
import { HelpTopic } from '../../../../common/models/help.interface';
import { APP_SETTINGS } from '../../app-settings';

@Injectable()
export class HelpTopicService {
    private api;
    private status;
    private helpTopics: ReplaySubject<HelpTopic[]>;
    private subscription: Subscription;

    constructor(private http: HttpClient, @Inject(APP_SETTINGS) private appSettings) {
        this.api = appSettings.apiEndpoint.concat('support/');
        this.status = this.appSettings.NOT_LOADED;
        this.helpTopics = new ReplaySubject(1);
    }

    get topics(): Observable<HelpTopic[]> {
        if (this.status === this.appSettings.NOT_LOADED) {
            this.refresh();
        }
        return this.helpTopics.asObservable();
    }

    refresh() {
        this.status = this.appSettings.LOADING;
        this.subscription = this.http
            .get<ApiResponse<HelpTopic[]>>(`${this.api}help/topics/`)
            .pipe(
                map(res => {
                    this.status = this.appSettings.LOADED;
                    this.helpTopics.next(res.data);
                }),
                catchError(error => {
                    this.status = this.appSettings.NOT_LOADED;
                    console.log(error);
                    return observableThrowError(error);
                })
            )
            .subscribe();
    }

    findTopic(topics, topicId): HelpTopic {
        return topics.filter(topic => topic.id === topicId)[0];
    }

    toTopics(topics: HelpTopic[]) {
        return topics.map(topic => {
            return {
                id: topic.id,
                caption: topic.topic,
                icon: 'ico-' + topic.id
            };
        });
    }
}
