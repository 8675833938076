import { Component, OnInit } from '@angular/core';

interface HelpMenu {
    caption: string;
    link: string;
}

@Component({
    selector: 'app-help-selector',
    templateUrl: './help-selector.component.html',
    styleUrls: ['./help-selector.component.sass']
})
export class HelpSelectorComponent {
    helpMenu: HelpMenu[] = [
        { caption: 'Help Center', link: '/support/help' },
        { caption: 'Support/Ticket', link: '/support/tickets' }
    ];
}
