import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APP_SETTINGS } from '../app-settings';
import { AttachmentPayload, CreateTicketPayload } from './models/support.interface';
import { ApiResponse } from '../../../common/models/api-response.interface';

@Injectable()
export class SupportService {
    private api;

    constructor(private http: HttpClient, @Inject(APP_SETTINGS) private appSettings) {
        this.api = appSettings.apiEndpoint.concat('support/');
    }

    getTicket(key: string) {
        return this.http
            .get<ApiResponse<any>>(`${this.api}tickets/${key}`)
            .pipe(map(res => res.data));
    }

    createTicket(ticket: CreateTicketPayload) {
        return this.http
            .post<ApiResponse<any>>(`${this.api}tickets`, ticket)
            .pipe(map(res => res.data));
    }

    addAttachment(payload: AttachmentPayload) {
        // TODO refactor to use form instead of FormData()
        const formData: FormData = new FormData();
        formData.append('attachment', payload.attachment);
        return this.http
            .post<ApiResponse<any>>(`${this.api}tickets/${payload.issueId}`, formData)
            .pipe(map(res => res.data));
    }

    addComment(issueId: string, comment: string) {
        return this.http
            .post<ApiResponse<any>>(`${this.api}tickets/${issueId}/comment`, comment)
            .pipe(map(res => res.data));
    }
}
