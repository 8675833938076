/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../okta.widget.sass.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../../layouts/layout-public/layout-public.component";
import * as i4 from "./reset-password.component";
import * as i5 from "../../shared/services/okta-auth.service";
import * as i6 from "@angular/router";
var styles_ResetPasswordComponent = [i0.styles];
var RenderType_ResetPasswordComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ResetPasswordComponent, data: {} });
export { RenderType_ResetPasswordComponent as RenderType_ResetPasswordComponent };
export function View_ResetPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "splash-card-wrapper-thin"], ["id", "okta-reset-password-container"]], null, null, null, null, null))], null, null); }
export function View_ResetPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reset-password", [], null, null, null, View_ResetPasswordComponent_0, RenderType_ResetPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i4.ResetPasswordComponent, [i5.OktaAuthService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetPasswordComponentNgFactory = i1.ɵccf("app-reset-password", i4.ResetPasswordComponent, View_ResetPasswordComponent_Host_0, {}, {}, []);
export { ResetPasswordComponentNgFactory as ResetPasswordComponentNgFactory };
