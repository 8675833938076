/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tos-view.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tos-view.component";
import * as i3 from "../tos.service";
var styles_TosViewComponent = [i0.styles];
var RenderType_TosViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TosViewComponent, data: {} });
export { RenderType_TosViewComponent as RenderType_TosViewComponent };
export function View_TosViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tos == null) ? null : _co.tos.content); _ck(_v, 0, 0, currVal_0); }); }
export function View_TosViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tos-view", [], null, null, null, View_TosViewComponent_0, RenderType_TosViewComponent)), i1.ɵdid(1, 114688, null, 0, i2.TosViewComponent, [i3.TosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TosViewComponentNgFactory = i1.ɵccf("app-tos-view", i2.TosViewComponent, View_TosViewComponent_Host_0, {}, {}, []);
export { TosViewComponentNgFactory as TosViewComponentNgFactory };
