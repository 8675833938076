import { InjectionToken } from '@angular/core';

export let APP_SETTINGS = new InjectionToken('app-settings');

export interface IAppSettings {
    apiEndpoint: string;
    LOADING: string;
    LOADED: string;
    NOT_LOADED: string;
}

export const AppSettings: IAppSettings = {
    apiEndpoint: '/api/',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    NOT_LOADED: 'NOT_LOADED'
};
