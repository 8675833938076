import { Component } from '@angular/core';
import { AppConfigService } from '../../shared/services/app-config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.sass'],
    inputs: ['isLight', 'isTos']
})
export class FooterComponent {
    currentYear: number;
    isLight: boolean;
    isTos: boolean;
    version: string = 'dev';
    constructor(appConfigService: AppConfigService) {
        appConfigService.config.subscribe(config => (this.version = config.version));
        this.currentYear = new Date().getFullYear();
    }
}
