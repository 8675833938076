import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { NewsBoxComponent } from './news-box/news-box.component';
import { HomeCardComponent } from './home-card/home-card.component';
import { HelpBoxComponent } from './help-box/help-box.component';
import { LayoutModule } from '../layouts/layout.module';
import { CoreModule } from '../shared/core.module';

@NgModule({
    imports: [CommonModule, LayoutModule, RouterModule, CoreModule],
    declarations: [HomeComponent, NewsBoxComponent, HomeCardComponent, HelpBoxComponent]
})
export class HomeModule {}
