import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStateService } from '../services/auth-state.service';

import { Observable } from 'rxjs';
import { AuthInvalidGuard } from './auth-invalid.guard';

@Injectable()
export class AuthInvalidErrorGuard extends AuthInvalidGuard {
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // if not authenticated, activate the route
        if (!this.authStateService.isAuthenticated) {
            return true;
        }
        // if authenticated, queue logout required error and take the user home
        this.router.navigate(['/error'], {
            queryParams: { code: 'logout_required' }
        });
        return false;
    }
}
