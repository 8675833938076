import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStateService } from '../services/auth-state.service';

import { Observable } from 'rxjs';

@Injectable()
export class SigninUsernameGuard implements CanActivate {
    constructor(private authStateService: AuthStateService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authStateService.getSavedUsername() || this.authStateService.getTempUsername()) {
            return true;
        } else {
            // if authenticated, then go to home / handle redirects
            this.router.navigate(['/login'], { queryParams: { username: '' } });
        }
    }
}
