import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Alert } from '../../../../../common/models/alert.interface';
import { AlertSeverity } from '../../../../../common/models/alert.interface';

@Component({
    selector: 'qb-alert',
    styleUrls: ['./alert.component.sass'],
    template: `
        <div class="qb-alert alert fade show" role="alert" *ngIf="alert" [ngClass]="alertClass">
            <div class="row justify-content-left">
                <div class="col-auto" *ngIf="alert.application">
                    <strong>{{ alert.application }}</strong>
                </div>
                <div class="col" [innerHTML]="alert.message"></div>
                <div *ngIf="alert.dismissButton" class="p-2 d-flex align-items-center">
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-info"
                        (click)="closeAlert()"
                    >
                        Dismiss
                    </button>
                </div>
            </div>
            <button
                *ngIf="!alert.dismissButton"
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeAlert()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    `
})
export class AlertComponent {
    constructor() {}
    @Output() onAlertClose = new EventEmitter<Alert>();

    private _alert: Alert;
    alertClass = {};
    @Input()
    set alert(alert: Alert) {
        this._alert = alert;
        if (this._alert) {
            this.alertClass = {
                'alert-danger': this._alert.severity === AlertSeverity.DANGER,
                'alert-success': this._alert.severity === AlertSeverity.SUCCESS,
                'alert-warning': this._alert.severity === AlertSeverity.WARNING,
                'alert-info': this._alert.severity === AlertSeverity.INFO,
                'qb-alert-wide': this._alert.wide,
                'alert-dismissible': !this._alert.dismissButton
            };
        } else {
            this.alertClass = {};
        }
    }
    get alert(): Alert {
        return this._alert;
    }

    closeAlert() {
        this.onAlertClose.emit(this.alert);
    }
}
