import { Pipe, PipeTransform } from '@angular/core';

/**
 * usage -  filename | truncname
 * usage with option -  filename | truncname:3
 */
@Pipe({
    name: 'truncname'
})
export class TruncNamePipe implements PipeTransform {
    transform(name: string, len: number = 15) {
        if (name.length <= len) {
            return name;
        }

        return name.slice(0, len) + '...';
    }
}
