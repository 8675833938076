import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-news-box',
    templateUrl: 'news-box.component.html',
    styleUrls: ['news-box.component.sass']
})
export class NewsBoxComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
