import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ExternalErrorComponent } from './external-error.component';
import { LayoutModule } from '../layouts/layout.module';

@NgModule({
    imports: [CommonModule, RouterModule, LayoutModule],
    declarations: [PageNotFoundComponent, ExternalErrorComponent]
})
export class ErrorModule {}
