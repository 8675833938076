import { throwError as observableThrowError, Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class UserProfileService {
    private urlPwd = '/api/change_pwd/';
    private urlProfile = '/api/user_profile/';

    constructor(private http: HttpClient) {}

    makeProfileFromClaims(claims) {
        //TODO: verify and update the code, when JWT content is finalized
        let profile = {
            email: claims.email || '',
            zoneinfo: claims.zoneinfo || '',
            updated_at: claims.updated_at,
            user_image: claims.user_image || '/assets/avatar_default.png',
            firstName: claims.given_name || '',
            lastName: claims.family_name || '',
            title: claims.title || '',
            officePhone: claims.officePhone || '',
            mobilePhone: claims.mobilePhone || '',
            street: claims.street || '',
            city: claims.city || '',
            zip: claims.zip || '',
            name: claims.name || '',
            username: claims.preferred_username
        };
        profile['date'] = new Date(profile.updated_at * 1000);
        return profile;
    }

    postChangePwd(oldPassword, newPassword) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };

        return this.http.post(this.urlPwd, { pwd: oldPassword, newPwd: newPassword }, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }

    postUpdateContact(profile) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };

        return this.http.post(this.urlProfile, profile, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }

    private extractData(res: HttpResponse<any>) {
        const body = res.body;
        return body.data || {};
    }

    private handleErrorObservable(error: HttpResponse<any> | any) {
        console.log(error.message || error);
        return observableThrowError(error.message || error);
    }
}
