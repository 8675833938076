import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hero',
    styleUrls: ['./hero.component.sass'],
    template: `
        <div class="qb-hero text-center">
            <h1>
                {{ hero.title }}
            </h1>
            <p class="lead" *ngIf="hero.lead">
                {{ hero.lead }}
            </p>
        </div>
    `
})
export class HeroComponent {
    @Input() hero;
}
