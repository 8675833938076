<form (ngSubmit)="onSubmit()" [formGroup]="loginNameForm">
    <div class="o-form-content clearfix">
        <div class="o-form-fieldset-container">
            <div class="o-form-fieldset">
                <div class="o-form-input">
                    <span class="o-form-input-name-username">
                        <span class="icon person-icon"></span>
                        <input
                            name="username"
                            type="email"
                            formControlName="username"
                            placeholder="Email"
                            autocomplete="off"
                        />
                    </span>
                </div>
            </div>
            <div class="o-form-button-bar">
                <input
                    class="btn btn-success"
                    type="submit"
                    [disabled]="loginNameForm.invalid"
                    value="Next"
                    id="okta-signin-submit"
                    data-type="save"
                />
            </div>
        </div>
    </div>
</form>
