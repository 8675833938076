import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner',
    styleUrls: ['./spinner.component.sass'],
    template: `
        <div class="loader"></div>
    `
})
export class SpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
