import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '../shared/services/auth-state.service';
import { Router } from '@angular/router';
import { UserProfileService } from './user-profile.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
    profile: any;
    changePwdFormShown: boolean = false;
    passwordChanged: boolean = false;
    passwordError: boolean = false;
    contactChanged: boolean = false;
    contactError: boolean = false;
    model: any = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    constructor(
        private authStateService: AuthStateService,
        private router: Router,
        private userProfileService: UserProfileService
    ) {}

    ngOnInit() {
        // TODO: do we need to call user profile api to get profile data, or JWS is enough?
        this.profile = this.userProfileService.makeProfileFromClaims(
            this.authStateService.getUserClaims()
        );
    }

    showChangePwd() {
        this.clearPasswordModel();
        this.changePwdFormShown = true;
        this.passwordChanged = false;
        this.passwordError = false;
    }

    hideChangePwd() {
        this.changePwdFormShown = false;
    }

    newPasswordIsValid() {
        //Your password must have at least 8 characters, a lowercase letter,
        // an uppercase letter, a number, no parts of your username (the latter will be checked by okta api).
        let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
        return this.model.newPassword.length && strongRegex.test(this.model.newPassword);
    }

    submitChangePassword() {
        this.userProfileService
            .postChangePwd(this.model.oldPassword, this.model.newPassword)
            .subscribe(
                response => {
                    this.changePwdFormShown = false;
                    this.passwordChanged = true;
                    this.passwordError = false;
                },
                error => {
                    this.changePwdFormShown = false;
                    this.passwordChanged = false;
                    this.passwordError = true;
                }
            );
    }

    private clearPasswordModel() {
        this.model.oldPassword = '';
        this.model.newPassword = '';
        this.model.confirmPassword = '';
    }

    updateImg() {
        //TODO show msg 'Not available at this time"
        window.alert('TODO: update avatar via image upload');
    }

    onContactSubmit() {
        // save edited first, last name, title, phones, address
        this.userProfileService.postUpdateContact(this.profile).subscribe(
            response => {
                this.contactChanged = true;
                this.contactError = false;
            },
            error => {
                this.contactChanged = false;
                this.contactError = true;
            }
        );
    }

    cancel() {
        this.router.navigate(['/home']);
    }
}
