<app-layout-public>
    <app-progress class="splash-card-loader" *ngIf="inProgress"></app-progress>
    <div class="splash-card-wrapper-thin">
        <div class="splash-card-header w-100 text-center">
            <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
        </div>
        <div class="splash-card-body mt-3">
            Please reach out to your Quotient Contact to get access to Quotient Business.
        </div>
        <div class="text-center mt-5">
            <button
                id="signupOkButton"
                type="button"
                (click)="backToLogin()"
                class="btn btn-primary"
            >
                OK
            </button>
        </div>
    </div>
    <ng-template #signup_disabled>
        <div class="splash-card-wrapper-thin">
            <div class="splash-card-header w-100 text-center">
                <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
                <div class="splash-card-header-title">Account Request</div>
            </div>
            <form
                class="qb-form"
                (ngSubmit)="onSubmit(signupForm.valid)"
                #signupForm="ngForm"
                novalidate
            >
                <div class="form-group">
                    <label for="firstName">First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        class="form-control"
                        required
                        #firstName="ngModel"
                        [(ngModel)]="user.firstName"
                    />
                    <div *ngIf="firstName.errors && firstName.dirty" class="err-msg">
                        First Name is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastName">Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        class="form-control"
                        required
                        #lastName="ngModel"
                        [(ngModel)]="user.lastName"
                    />
                    <div *ngIf="lastName.errors && lastName.dirty" class="err-msg">
                        Last Name is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        class="form-control"
                        required
                        #email="ngModel"
                        email
                        [(ngModel)]="user.email"
                    />
                    <div *ngIf="email.errors && email.dirty" class="err-msg">
                        Valid email is required
                    </div>
                    <div
                        *ngIf="!email.errors && email.errors?.email && email.dirty"
                        class="err-msg"
                    >
                        Email is invalid
                    </div>
                </div>
                <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        class="form-control"
                        required
                        #companyName="ngModel"
                        [(ngModel)]="user.companyName"
                    />
                    <div *ngIf="companyName.errors && companyName.dirty" class="err-msg">
                        Company Name is required
                    </div>
                </div>
                <div class="center">
                    <div class="captcha-box mt-4">
                        <re-captcha
                            *ngIf="captchaKey"
                            name="captcha"
                            required
                            #gCaptcha="ngModel"
                            [(ngModel)]="user.captcha"
                            siteKey="{{ captchaKey }}"
                        >
                        </re-captcha>
                    </div>
                    <div class="mt-4">
                        <button
                            id="ga-request-account"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="signupForm.invalid"
                        >
                            Submit
                        </button>
                        <button class="btn btn-outline-light" type="button" (click)="cancel()">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
    <ng-template #thankyou>
        <div class="splash-card-wrapper-wide">
            <div class="splash-card-header w-100 text-center">
                <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
                <div class="splash-card-header-title">Thank You</div>
            </div>
            <div class="splash-card-body">
                Thank you for submitting the required information. The verification process may take
                from 24 to 48 hours. You will be notified with the result, by email within this
                period of time.
            </div>
            <div class="text-center mt-5">
                <button
                    id="signupOkButton"
                    type="button"
                    (click)="backToLogin()"
                    class="btn btn-primary"
                >
                    OK
                </button>
            </div>
        </div>
    </ng-template>
</app-layout-public>
