import { throwError as observableThrowError, ReplaySubject, Subscription, Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { TicketList } from '../../../../common/models/ticket.interface';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { APP_SETTINGS } from '../../app-settings';
import { ApiResponse } from '../../../../common/models/api-response.interface';

export class ListTicketService {
    private api;
    private status;
    private tickets: ReplaySubject<TicketList[]>;
    private subscription: Subscription;

    constructor(private http: HttpClient, @Inject(APP_SETTINGS) private appSettings) {
        this.api = appSettings.apiEndpoint.concat('support/');
        this.status = this.appSettings.NOT_LOADED;
        this.tickets = new ReplaySubject(1);
    }

    refresh() {
        this.status = this.appSettings.LOADING;
        this.subscription = this.http
            .get<ApiResponse<TicketList[]>>(`${this.api}tickets/`)
            .pipe(
                map(res => {
                    this.status = this.appSettings.LOADED;
                    this.tickets.next(res.data);
                }),
                catchError(error => {
                    this.status = this.appSettings.NOT_LOADED;
                    console.error(error);
                    return observableThrowError(error);
                })
            )
            .subscribe();
    }

    get jiraTickets() {
        if (this.status === this.appSettings.NOT_LOADED) {
            this.refresh();
        }
        return this.tickets.asObservable();
    }
}
