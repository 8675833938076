import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '../../shared/services/auth-state.service';

@Component({
    selector: 'app-home-card',
    templateUrl: './home-card.component.html',
    styleUrls: ['./home-card.component.sass']
})
export class HomeCardComponent implements OnInit {
    @Input() app: any;
    isInternalUser: boolean;

    constructor(private router: Router, private authStateService: AuthStateService) {}

    ngOnInit() {
        this.isInternalUser = false;
        this.authStateService.getClaimsSubscription().subscribe(claims => {
            if (claims) {
                this.isInternalUser = this.authStateService.isInternalUser(
                    claims.preferred_username
                );
            }
        });
    }

    requestAccess() {
        const path = '/support/create-ticket';
        const params = {
            queryParams: {
                app: 'business',
                ticket_type: 'app_access',
                reason: `${this.app.access_key}_access`
            }
        };
        this.navigateTo(path, params);
    }

    /**
     *  path example: this.router.navigate(['/login'], { queryParams: { username: '' } });
     * @param {string} path
     * @param {Object} params
     */
    navigateTo(path: string, params: object) {
        this.router.navigate([path], params);
    }
}
