import { Component, OnInit } from '@angular/core';
import { AppListService } from '../../../shared/services/app-list.service';
import { Application } from '../../../../../common/models/application.interface';

/**
 * Display of dotted hamburger menu with application selector
 */
@Component({
    selector: 'app-selector',
    templateUrl: './app-selector.component.html',
    styleUrls: ['./app-selector.component.sass']
})
export class AppSelectorComponent implements OnInit {
    // list of available applications
    apps: Application[];
    errorMessage: any;

    constructor(private appListService: AppListService) {}

    ngOnInit() {
        this.getApps();
    }

    onOpen() {
        this.getApps();
    }

    getApps() {
        this.appListService.applications.subscribe(
            apps => (this.apps = apps.filter(app => app.enabled)),
            error => (this.errorMessage = <any>error)
        );
    }
}
