import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '../shared/services/auth-state.service';

@Component({
    selector: 'app-app-debug',
    templateUrl: './app-debug.component.html',
    styleUrls: ['./app-debug.component.sass']
})
export class AppDebugComponent implements OnInit {
    model = {
        token: `eyJraWQiOiJfTGgtYlpKSzdiWkM3NV9sSTNTdGFQdFZ5eGlDWjBPVDZRbnZZeENGb09FIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVkdGdhNWNvQWh6SXZwMTBoNyIsIm5hbWUiOiJTaGFpdnlhIE1haGFqYW4iLCJsb2NhbGUiOiJlbi1VUyIsImVtYWlsIjoic21haGFqYW5AcXVvdGllbnQuY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL3F1b3RpZW50LWN1c3RvbWVyLm9rdGFwcmV2aWV3LmNvbSIsImF1ZCI6IjBvYWN3NWlxcmRDam9sQktYMGg3IiwiaWF0IjoxNTE5MjUxNjkxLCJleHAiOjE1MTkyNTUyOTEsImp0aSI6IklELlp5M0ItaDVoR2FOd3I3UDNOVVF2QlNUeF9Db0tLWDFjaEhYeldpelp1WUEiLCJhbXIiOlsicHdkIl0sImlkcCI6IjBvYWJ6bHU1cG5wbzVoSlIwMGg3IiwicHJlZmVycmVkX3VzZXJuYW1lIjoic21haGFqYW5AcXVvdGllbnQuY29tIiwiZ2l2ZW5fbmFtZSI6IlNoYWl2eWEiLCJmYW1pbHlfbmFtZSI6Ik1haGFqYW4iLCJ6b25laW5mbyI6IkFtZXJpY2EvTG9zX0FuZ2VsZXMiLCJ1cGRhdGVkX2F0IjoxNTE5MjMzNDYzLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXV0aF90aW1lIjoxNTE5MjUxNjkwLCJlbmFibGVkX3Nzb19hcHBzIjpbImNhbXBhaWducyIsImFuYWx5dGljcyIsInJldGFpbGVyX3Rvb2xzIl19.QR0BIXq3A5bHTweHYLZRMusfbVA66cGLULXaxZVM1SLyQQRGB2SLzXXN-euN3Ri7jFbcTm36mSGov1zNwTiFPtUbhKHUpDK8i2iRIVWn00-synDm_CZRxhzRcm7vEYQeVVLlBe8tQNEjfIxZwh8HeRbGRnYMW-WhdIN2pCQAdU8pc0fceB2z8R9_5vq7rP_LyzoYppJpxjDOYX1NZ1UR2GpRgYPwM7Lq4-6QaxYqo7Sa1uauZzMOyl0q-DA7d-OWIF4wNHNZdACPayX7L73zxpXvdS_-q_mNwBb_BZrmwWX2ar6N7WUG8NRD6jxTYSCE9jQ5KGS65BaASeZ3EX4dDQ`
    };
    submitted;

    constructor(private authStateService: AuthStateService) {}

    ngOnInit() {
        this.submitted = false;
    }

    /**
     * debug token expired scenario by setting expired token
     */
    onSubmit() {
        this.submitted = true;
        this.authStateService.setIdToken(this.model.token);
        console.log(this.model);
    }
}
