import { Component } from '@angular/core';

@Component({
    selector: 'app-support',
    styleUrls: ['./support.component.sass'],
    template: `
        <app-layout-secure>
            <router-outlet></router-outlet>
        </app-layout-secure>
    `
})
export class SupportComponent {}
