import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { Okta } from '../../../../common/models/okta.interface';
import PasswordPolicy = Okta.PasswordPolicy;
import { AppAlert, AppAlertService } from '../../shared/services/app-alert.service';
import { AlertSeverity } from '../../../../common/models/alert.interface';

export interface PasswordForm {
    newPassword: string;
    confirmPassword: string;
}

interface PrimaryAuthResponse {
    stateToken: string;
    expiresAt: Date;
    status: string;
    recoveryType: string;
    _embedded: {
        user: any;
        policy: PasswordPolicy;
    };
}

interface CreatePasswordResponse {
    stateToken: string;
    expiresAt: Date;
    status: string;
}

@Injectable()
export class ActivationService {
    private state: string = 'begin';
    private stateToken: string;
    private activationEndpoint = '/api/v1/authn';
    private passwordEndpoint = '/api/v1/authn/credentials/reset_password';
    private passwordPolicy: PasswordPolicy;
    private errorMessages = {
        E0000004:
            'Unfortunately your activation link has expired or is invalid. Please contact your Quotient Account Team for further assistance.',
        E0000011:
            'Unfortunately your activation link has expired. Please contact your Quotient Account Team for further assistance.',
        E0000080: 'Invalid password, please follow the requirements below and try again.',
        default:
            'Sorry, we are facing some issues activating your account. Please contact your Quotient Account Team for further assistance.'
    };

    constructor(
        private http: HttpClient,
        private authStateService: AuthStateService,
        private appAlertService: AppAlertService
    ) {}

    resetState() {
        this.state = 'begin';
        this.stateToken = null;
    }

    activateUser(username: string, activationToken: string): Promise<string> {
        let activationUrl =
            this.authStateService.getOktaBaseUrl(username) + this.activationEndpoint;

        return this.http
            .post<PrimaryAuthResponse>(activationUrl, {
                token: activationToken
            })
            .toPromise()
            .then(data => {
                this.state = data.status;
                this.stateToken = data.stateToken;
                this.passwordPolicy = data._embedded.policy;
                return this.state;
            })
            .catch(err => this.handleError(err));
    }

    createPassword(username: string, passwordForm: PasswordForm): Promise<string> {
        let passwordUrl = this.authStateService.getOktaBaseUrl(username) + this.passwordEndpoint;
        return this.http
            .post<CreatePasswordResponse>(passwordUrl, {
                newPassword: passwordForm.newPassword,
                stateToken: this.stateToken
            })
            .toPromise()
            .then(createPasswordResponse => {
                this.state = createPasswordResponse.status;
                this.stateToken = createPasswordResponse.stateToken;
                return this.state;
            })
            .catch(err => this.handleError(err));
    }

    handleError(err) {
        let errorMessage =
            err.error && err.error.errorCode && this.errorMessages[err.error.errorCode]
                ? this.errorMessages[err.error.errorCode]
                : this.errorMessages['default'];

        this.appAlertService.queueAlert(new AppAlert(AlertSeverity.DANGER, errorMessage));
        return 'ERROR';
    }

    getStatePasswordPolicy() {
        return this.passwordPolicy;
    }
}
