import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { Okta } from '../../../../common/models/okta.interface';

@Directive({
    selector: '[passwordValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }]
})
export class PasswordValidatorDirective implements Validator {
    @Input('passwordValidator') config: { policy: Okta.PasswordPolicy; username: string };

    countChars(password: string) {
        let counters = {
            lower: 0,
            upper: 0,
            symbol: 0,
            number: 0
        };

        if (!password) {
            return counters;
        }

        for (let char of password) {
            if (char.match(/[a-z]/)) {
                counters.lower++;
            } else if (char.match(/[A-Z]/)) {
                counters.upper++;
            } else if (char.match(/[!@#$%^&*]/)) {
                counters.symbol++;
            } else if (char.match(/[0-9]/)) {
                counters.number++;
            }
        }

        return counters;
    }

    containsUsername(password: string) {
        if (!this.config.username) {
            return false;
        }
        const usernameRe = /(.*)@(.*)\.\w+/gi;
        let matcher = usernameRe.exec(this.config.username);
        if (matcher && matcher.length > 2) {
            let lcPassword = password.toLowerCase();
            for (let elem of matcher) {
                if (lcPassword.indexOf(elem) >= 0) {
                    return true;
                }
            }
        }
        return false;
    }

    validate(control: AbstractControl): { [key: string]: any } {
        let errorObject = { passwordValidator: { message: null } };
        let password = control.value;
        let counts: any = this.countChars(password);
        let complexity = this.config.policy.complexity;

        if (!password || password.length < complexity.minLength) {
            errorObject.passwordValidator.message = `Passwords must be at least ${complexity.minLength} characters`;
        } else if (
            counts.lower < complexity.minLowerCase ||
            counts.upper < complexity.minUpperCase ||
            counts.symbol < complexity.minSymbol ||
            counts.number < complexity.minNumber
        ) {
            // TODO: Dynamic message
            errorObject.passwordValidator.message = `Passwords must contain a upper and lower case letter and number.`;
        } else if (this.containsUsername(password)) {
            errorObject.passwordValidator.message = `Passwords must not contain parts of your username.`;
        } else {
            errorObject = null;
        }

        return errorObject;
    }
}
