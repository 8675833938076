<div class="dash-card">
    <div class="dash-card-head">
        <h1>
            <span class="ico-news"></span>
            News & Events
        </h1>
    </div>
    <div class="dash-card-body">
        <ul class="list-disc">
            <li>
                <a href="https://www.quotient.com/news/" target="_blank">
                    News about and from our company
                </a>
            </li>
            <li>
                <a href="https://www.quotient.com/media-resources/" target="_blank">
                    Media Information
                </a>
            </li>
            <li>
                <a href="https://www.quotient.com/blog/" target="_blank">
                    Quotient Blog
                </a>
            </li>
        </ul>
    </div>
    <div class="dash-card-footer">
        <div class="view-all-link">
            <a href="https://www.quotient.com/news/" target="_blank">
                View All News
            </a>
        </div>
    </div>
</div>
