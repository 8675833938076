/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "../../shared/services/app-config.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [["class", "footer-hr"]], null, null, null, null, null))], null, null); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" |"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "/tos"], ["target", "_new"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms of Use"]))], null, null); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" | v", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 1, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "footer", [["class", "footer"], ["on", ""]], [[2, "light", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "footer-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\u00A9 ", " Quotient Technology Inc. All Rights Reserved."])), (_l()(), i1.ɵted(-1, null, [" |"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["href", "//www.quotient.com/privacy-policy/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Statement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isLight; _ck(_v, 2, 0, currVal_1); var currVal_3 = !_co.isTos; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.isLight; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLight; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.currentYear; _ck(_v, 6, 0, currVal_2); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.FooterComponent, [i4.AppConfigService], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, { isLight: "isLight", isTos: "isTos" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
