import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SignupUser } from '../../../../common/models/signup-user.interface';
import { APP_SETTINGS } from '../../app-settings';
import { AppAlert, AppAlertService } from '../../shared/services/app-alert.service';
import { AlertSeverity } from '../../../../common/models/alert.interface';
import { ApiResponse } from '../../../../common/models/api-response.interface';

@Injectable()
export class SignupFormService {
    private api;

    constructor(
        private http: HttpClient,
        @Inject(APP_SETTINGS) private appSettings,
        private appAlertService: AppAlertService
    ) {
        this.api = this.appSettings.apiEndpoint.concat('signup/');
    }

    signup(user: SignupUser) {
        return this.http.post<ApiResponse<any>>(this.api, user).pipe(map(res => res.data));
    }
}
