<app-layout-public>
    <div class="splash-card-wrapper-thin">
        <div class="splash-card-header w-100 text-center">
            <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
            <div class="splash-card-header-title">Sign In</div>
        </div>
        <app-login-name-form></app-login-name-form>
        <div class="hr-stike"><span> OR </span></div>
        <a id="requestAccountBtn" class="w-100 btn btn-success" [routerLink]="['/signup']">
            Request An Account
        </a>
    </div>
</app-layout-public>
