import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'LogoutComponent',
    template: ' '
})
export class LogoutComponent implements OnInit {
    profile: any;

    constructor(
        private authStateService: AuthStateService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.authStateService.logout();
        this.router.navigate(['/login']);
    }
}
