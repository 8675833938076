import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UserProfileComponent } from './user-profile.component';
import { UserProfileService } from './user-profile.service';
import { LayoutModule } from '../layouts/layout.module';

@NgModule({
    declarations: [UserProfileComponent],
    imports: [CommonModule, FormsModule, LayoutModule],
    providers: [UserProfileService]
})
export class ProfileModule {}
