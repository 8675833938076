import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '../../../shared/services/auth-state.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-login-name-form',
    templateUrl: './login-name-form.component.html',
    styleUrls: ['../login-name.component.sass']
})
export class LoginNameFormComponent implements OnInit {
    loginNameForm: FormGroup;

    constructor(
        private router: Router,
        private zone: NgZone,
        private authStateService: AuthStateService,
        private formBuilder: FormBuilder
    ) {
        this.loginNameForm = this.formBuilder.group({
            username: ['', [Validators.email, Validators.required]]
        });
    }

    ngOnInit() {}

    onSubmit() {
        // stop here if form is invalid
        if (this.loginNameForm.invalid) {
            return;
        }

        // TODO for some reason a second form submit is triggered after the router navigate, seems to be a zone.js bug?
        // this timeout prevents that phantom event from occurring.
        setTimeout(
            () =>
                this.zone.run(() => {
                    this.authStateService.saveTempUsername(this.loginNameForm.value.username);
                    return this.router.navigate(['/signin']);
                }),
            75
        );
    }
}
