import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-help-box',
    templateUrl: 'help-box.component.html',
    styleUrls: ['help-box.component.sass']
})
export class HelpBoxComponent implements OnInit {
    ngOnInit() {}
}
