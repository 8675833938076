/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-bare.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-bare.component";
var styles_HeaderBareComponent = [i0.styles];
var RenderType_HeaderBareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderBareComponent, data: {} });
export { RenderType_HeaderBareComponent as RenderType_HeaderBareComponent };
export function View_HeaderBareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "splash-header"]], null, null, null, null, null))], null, null); }
export function View_HeaderBareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-bare", [], null, null, null, View_HeaderBareComponent_0, RenderType_HeaderBareComponent)), i1.ɵdid(1, 49152, null, 0, i2.HeaderBareComponent, [], null, null)], null, null); }
var HeaderBareComponentNgFactory = i1.ɵccf("app-header-bare", i2.HeaderBareComponent, View_HeaderBareComponent_Host_0, {}, {}, []);
export { HeaderBareComponentNgFactory as HeaderBareComponentNgFactory };
