<app-layout-secure>
    <div class="row bg-dashboard">
        <div class="col">
            <div class="qb-hero-home">
                <app-hero [hero]="hero"></app-hero>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="home-container">
                <div class="flex-row">
                    <app-home-card [app]="app" *ngFor="let app of apps"></app-home-card>
                </div>
                <div class="flex-row">
                    <app-help-box></app-help-box>
                    <app-news-box></app-news-box>
                </div>
            </div>
        </div>
    </div>
</app-layout-secure>
