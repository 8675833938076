import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SigninComponent } from './login/signin/signin.component';
import { LoginNameComponent } from './login/login-name/login-name.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { AuthValidGuard } from './shared/guards/auth-valid.guard';
import { LoginParametersGuard } from './shared/guards/login-parameter.guard';
import { AuthInvalidGuard } from './shared/guards/auth-invalid.guard';
import { LogoutComponent } from './login/logout/logout.component';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { SignupComponent } from './login/signup/signup.component';
import { SigninUsernameGuard } from './shared/guards/signin-username.guard';
import { ActivationComponent } from './login/activation/activation.component';
import { ExternalErrorComponent } from './error/external-error.component';
import { QuotientResetPasswordComponent } from './login/quotient-reset-password/quotient-reset-password.component';
import { AuthInvalidErrorGuard } from './shared/guards/auth-invalid-error.guard';
import { AppDebugComponent } from './app-debug/app-debug.component';
import { ErrorRedirectGuard } from './shared/guards/error-redirect.guard';
const ɵ0 = () => import("./support/support.module.ngfactory").then(m => m.SupportModuleNgFactory), ɵ1 = () => import("./tos/tos.module.ngfactory").then(m => m.TosModuleNgFactory);
const routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthValidGuard]
    },
    {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthValidGuard]
    },
    {
        path: 'login',
        component: LoginNameComponent,
        canActivate: [LoginParametersGuard, AuthInvalidGuard]
    },
    {
        path: 'signin',
        component: SigninComponent,
        canActivate: [AuthInvalidGuard, SigninUsernameGuard]
    },
    { path: 'signup', component: SignupComponent, canActivate: [AuthInvalidGuard] },
    { path: 'activate', component: ActivationComponent, canActivate: [AuthInvalidErrorGuard] },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AuthValidGuard]
    },
    {
        path: 'error',
        component: ExternalErrorComponent,
        // Save error redirect URL, and if auth recovered from errors redirect the user
        canActivate: [ErrorRedirectGuard, AuthValidGuard]
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        canActivate: [AuthInvalidErrorGuard]
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [AuthInvalidErrorGuard]
    },
    {
        path: 'quotient-reset-password',
        component: QuotientResetPasswordComponent,
        canActivate: [AuthInvalidErrorGuard]
    },
    {
        path: 'support',
        loadChildren: ɵ0
    },
    { path: 'tos', loadChildren: ɵ1 },
    { path: 'debug', component: AppDebugComponent, canActivate: [AuthValidGuard] },
    { path: '**', component: PageNotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
