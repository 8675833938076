/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../okta.widget.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../../layouts/layout-public/layout-public.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./quotient-reset-password.component";
var styles_QuotientResetPasswordComponent = [i0.styles];
var RenderType_QuotientResetPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuotientResetPasswordComponent, data: {} });
export { RenderType_QuotientResetPasswordComponent as RenderType_QuotientResetPasswordComponent };
export function View_QuotientResetPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "splash-card-header w-100 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "splash-card-header-logo"], ["src", "/assets/form_q_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "splash-card-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forgot Password"])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "div", [["class", "splash-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you have forgotten your Quotient corporate credentials please contact helpdesk@quotient.com for assistance. "])), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "div", [["class", "splash-card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["routerLink", "/signin"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Back to Sign In"]))], function (_ck, _v) { var currVal_2 = "/signin"; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).target; var currVal_1 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_QuotientResetPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qb-quotient-reset-password", [], null, null, null, View_QuotientResetPasswordComponent_0, RenderType_QuotientResetPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i6.QuotientResetPasswordComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuotientResetPasswordComponentNgFactory = i1.ɵccf("qb-quotient-reset-password", i6.QuotientResetPasswordComponent, View_QuotientResetPasswordComponent_Host_0, {}, {}, []);
export { QuotientResetPasswordComponentNgFactory as QuotientResetPasswordComponentNgFactory };
