import { of as observableOf, Observable } from 'rxjs';

import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../common/models/app-config.interface';
import { AbstractCachingApi } from '../services/abstract-caching-api.service';

@Injectable()
export class AppConfigService extends AbstractCachingApi<AppConfig[]> {
    protected get baseUrl() {
        return this.appSettings.apiEndpoint + 'configs';
    }

    public get config(): Observable<AppConfig> {
        return this.list.pipe(
            mergeMap(appConfigs => {
                return observableOf(appConfigs[0]);
            })
        );
    }
}
