/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hero.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hero.component";
var styles_HeroComponent = [i0.styles];
var RenderType_HeroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero.lead; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "qb-hero text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hero.lead; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_HeroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hero", [], null, null, null, View_HeroComponent_0, RenderType_HeroComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeroComponent, [], null, null)], null, null); }
var HeroComponentNgFactory = i1.ɵccf("app-hero", i3.HeroComponent, View_HeroComponent_Host_0, { hero: "hero" }, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };
