import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { LayoutPublicComponent } from './layout-public/layout-public.component';
import { LayoutSecureComponent } from './layout-secure/layout-secure.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderBareComponent } from './header/header-bare/header-bare.component';
import { HeaderFullComponent } from './header/header-full.component';
import { AppSelectorComponent } from './header/app-selector/app-selector.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { CoreModule } from '../shared/core.module';
import { HelpSelectorComponent } from './header/help-selector/help-selector.component';

@NgModule({
    declarations: [
        LayoutPublicComponent,
        LayoutSecureComponent,
        FooterComponent,
        HeaderBareComponent,
        HeaderFullComponent,
        AppSelectorComponent,
        UserMenuComponent,
        HelpSelectorComponent
    ],
    imports: [CommonModule, RouterModule, NgbDropdownModule, CoreModule],
    exports: [LayoutPublicComponent, LayoutSecureComponent]
})
export class LayoutModule {}
