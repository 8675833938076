<div class="row bg-support">
    <div class="col">
        <div class="qb-hero-support">
            <app-hero [hero]="hero"></app-hero>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="flex-container">
            <div class="flex-item">
                <div class="card qb-card">
                    <div *ngIf="!isSubmitted; else formSubmitted">
                        <div class="card-header">
                            <h4 class="card-title text-center" *ngIf="!isInternalAccessRequest">
                                Create Ticket
                            </h4>
                            <h4 class="card-title text-center" *ngIf="isInternalAccessRequest">
                                Quotient Employee Access Requests
                            </h4>
                        </div>
                        <div class="card-body" *ngIf="isInternalAccessRequest">
                            <ul>
                                <li>
                                    Please create an access ticket using
                                    <a href="https://quotient.atlassian.net/jira/">JIRA</a>.
                                </li>
                                <li>
                                    Here is a wiki for assistance:
                                    <a
                                        href="https://quotient.atlassian.net/wiki/spaces/HTCJTFAM/overview"
                                        >How to Create Jira Ticket for Access Management</a
                                    >
                                </li>
                                <li>
                                    Please reach out to IT team using their
                                    <a
                                        href="https://quotient.atlassian.net/servicedesk/customer/portal/10"
                                        >Helpdesk</a
                                    >
                                    if there are any issues accessing JIRA.
                                </li>
                            </ul>
                        </div>
                        <div class="card-body" *ngIf="!isInternalAccessRequest">
                            <div class="qb-form-wrapper">
                                <form
                                    class="qb-form"
                                    (ngSubmit)="createTicket(createTicketForm)"
                                    #createTicketForm="ngForm"
                                    novalidate
                                >
                                    <div class="form-group">
                                        <label for="selectApp">Application Name</label>
                                        <select
                                            class="form-control"
                                            id="selectApp"
                                            name="selectApp"
                                            required
                                            (change)="onAppChange()"
                                            [(ngModel)]="ticket.selectedApp"
                                        >
                                            <option value="">-Select Name-</option>
                                            <option *ngFor="let app of apps" [ngValue]="app">
                                                {{ app.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="selectTicketType">Ticket Type</label>
                                        <select
                                            class="form-control"
                                            id="selectTicketType"
                                            name="selectTicketType"
                                            required
                                            (change)="onTicketTypeChange()"
                                            [disabled]="!ticket.selectedApp"
                                            [(ngModel)]="ticket.selectedTicketType"
                                            #selectedTicketType="ngModel"
                                        >
                                            <option value="">-Select Type-</option>
                                            <option
                                                *ngFor="
                                                    let ticketType of ticket.selectedApp.ticketTypes
                                                "
                                                [ngValue]="ticketType"
                                            >
                                                {{ ticketType.value }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="selectReason">Reason</label>
                                        <select
                                            class="form-control"
                                            id="selectReason"
                                            name="selectReason"
                                            required
                                            [disabled]="
                                                !ticket.selectedApp || !ticket.selectedTicketType
                                            "
                                            [(ngModel)]="ticket.selectedReason"
                                        >
                                            <option value="">-Select Reason-</option>
                                            <option
                                                *ngFor="
                                                    let reason of ticket.selectedTicketType
                                                        .ticketReasons
                                                "
                                                [ngValue]="reason"
                                            >
                                                {{ reason.value }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputSubject">Subject</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="inputSubject"
                                            placeholder="Enter subject"
                                            name="inputSubject"
                                            required
                                            #inputSubject="ngModel"
                                            [(ngModel)]="ticket.subject"
                                            (change)="ticket.subject = ticket.subject.trim()"
                                        />
                                        <small
                                            class="form-text text-muted err-msg"
                                            *ngIf="
                                                inputSubject.errors?.required && inputSubject.dirty
                                            "
                                        >
                                            Please enter subject.
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputDescription">Description</label>
                                        <textarea
                                            class="form-control"
                                            id="inputDescription"
                                            rows="3"
                                            placeholder="Enter description"
                                            name="description"
                                            required
                                            [(ngModel)]="ticket.description"
                                            #inputDescription="ngModel"
                                            (change)="
                                                ticket.description = ticket.description.trim()
                                            "
                                        >
                                        </textarea>
                                        <small
                                            class="form-text text-muted"
                                            *ngIf="
                                                inputDescription.errors?.required &&
                                                inputDescription.dirty
                                            "
                                        >
                                            Please enter Description.
                                        </small>
                                    </div>
                                    <div class="text-center">
                                        <button
                                            type="submit"
                                            id="ga-submit-ticket"
                                            class="btn btn-primary"
                                            [disabled]="createTicketForm.invalid || inProgress"
                                        >
                                            Create Ticket
                                        </button>
                                        <button
                                            class="btn btn-outline-secondary"
                                            type="button"
                                            (click)="cancel()"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- form submitted -->
                    <ng-template #formSubmitted>
                        <div class="card-header">
                            <a [routerLink]="['/support/tickets']" class="backtolist">
                                &lt; View ticket list
                            </a>
                            <h4 class="card-title card-title-offset text-center">Create Ticket</h4>
                        </div>
                        <div class="card-body">
                            <div class="form-success" *ngIf="formSubmit?.isSuccess">
                                <img
                                    src="../../../assets/icons/check_mark.png"
                                    class="success-icon"
                                />
                                <h3 class="form-success-title">
                                    Ticket #{{ formSubmit?.message }} created successfully
                                </h3>
                                <p class="subtext">
                                    Quotient will contact you in 48 hours.
                                </p>
                                <br />
                                <button
                                    class="btn btn-primary"
                                    (click)="gotoTicket(formSubmit?.message)"
                                >
                                    Review Detail
                                </button>
                                <button class="btn btn-outline-secondary" (click)="onCreateNew()">
                                    Create New Ticket
                                </button>
                            </div>
                            <div class="alert alert-warning" role="alert" *ngIf="error">
                                <strong>{{ error?.status }}</strong>
                                <span>{{ error.message }}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
