<div class="dash-card">
    <div class="dash-card-head">
        <h1>
            <span class="ico-help"></span>
            Help Center
        </h1>
    </div>
    <div class="dash-card-body">
        <p>
            Visit the <a [routerLink]="['/support/help/']">Help Center</a> for any questions or
            concerns. User Guides, FAQs, help with profile customization, or help contacting
            Quotient can all be found in our <a [routerLink]="['/support/help/']">Help Center</a>.
        </p>
    </div>
    <div class="dash-card-footer">
        <div class="view-all-link">
            <a href="/support/help">View All Help</a>
        </div>
    </div>
</div>
