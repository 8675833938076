/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../layouts/layout-secure/layout-secure.component.ngfactory";
import * as i3 from "../layouts/layout-secure/layout-secure.component";
import * as i4 from "@angular/router";
import * as i5 from "./support.component";
var styles_SupportComponent = [i0.styles];
var RenderType_SupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportComponent, data: {} });
export { RenderType_SupportComponent as RenderType_SupportComponent };
export function View_SupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-layout-secure", [], null, null, null, i2.View_LayoutSecureComponent_0, i2.RenderType_LayoutSecureComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutSecureComponent, [], null, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_SupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support", [], null, null, null, View_SupportComponent_0, RenderType_SupportComponent)), i1.ɵdid(1, 49152, null, 0, i5.SupportComponent, [], null, null)], null, null); }
var SupportComponentNgFactory = i1.ɵccf("app-support", i5.SupportComponent, View_SupportComponent_Host_0, {}, {}, []);
export { SupportComponentNgFactory as SupportComponentNgFactory };
