<p class="mb-0" [innerHTML]="tos?.content"></p>
<hr />
<form name="formTos" #formTos="ngForm" (submit)="accept()" novalidate>
    <p class="mt-1 mb-3">
        <label>
            <input
                name="accept"
                type="checkbox"
                required
                [(ngModel)]="tosAcceptance.isAccepted"
            />&nbsp; I have read and agree to the Quotient Terms of Use.
        </label>
    </p>
    <p class="text-center mb-0 mt-1">
        <button type="submit" class="btn btn-primary mb-0" [disabled]="formTos.invalid">
            Continue
        </button>
    </p>
</form>
