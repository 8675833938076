<div class="row bg-help">
    <div class="col">
        <div class="qb-hero-support">
            <app-hero [hero]="hero"></app-hero>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="flex-container">
            <div class="flex-item">
                <div class="card">
                    <div class="card-body">
                        <section class="topic-container">
                            <a [routerLink]="['/support/help']" class="mb-2">
                                &lt; Back to Help
                            </a>
                            <h1 class="section-title">{{ topic?.topic }}</h1>
                            <div [innerHTML]="topic?.description"></div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
