<app-layout-public>
    <div class="splash-card-body" [ngSwitch]="state">
        <div *ngSwitchDefault>
            <div class="splash-card-header w-100 text-center">
                <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
                <div class="splash-card-header-title">Account Activation</div>
            </div>
            <div class="row splash-card-body">
                <span class="text-left col-sm-12" [hidden]="isInternalUser">
                    Hello {{ name }}, welcome to Quotient Business. Your account is almost ready,
                    please click Activate below to get started.
                </span>
                <span class="text-left col-sm-12" [hidden]="!isInternalUser">
                    Hello {{ name }}, welcome to Quotient Business. Your account is ready, please
                    sign in using your Quotient IT credentials.
                </span>
            </div>
            <div class="row mt-5">
                <button class="btn btn-primary col-sm-6 offset-sm-3" (click)="start()">
                    {{ isInternalUser ? 'Sign In' : 'Activate' }}
                </button>
            </div>
        </div>
        <div class="splash-card-wrapper-thin" *ngSwitchCase="'PASSWORD_RESET'">
            <div class="splash-card-header w-100 text-center">
                <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
                <div class="splash-card-header-title">Create Password</div>
            </div>
            <div>
                Passwords must meet the following requirements:
                <ul>
                    <li>Minimum length of 8 characters</li>
                    <li>Should not contain parts of your username</li>
                    <li>At least one lower case character</li>
                    <li>At least one upper case character</li>
                    <li>At least one numerical (0-9) character</li>
                </ul>
            </div>
            <hr />
            <form
                (ngSubmit)="createPassword()"
                #createPasswordForm="ngForm"
                class="qb-form"
                novalidate
            >
                <div class="form-group">
                    <label for="newPassword">New Password</label>
                    <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        class="form-control"
                        required
                        #newPassword="ngModel"
                        [(ngModel)]="passwordModel.newPassword"
                        [passwordValidator]="{ policy: passwordPolicy, username: username }"
                    />
                    <div *ngIf="newPassword.dirty && newPassword.errors?.required" class="err-msg">
                        Password is required
                    </div>
                    <div
                        class="err-msg"
                        *ngIf="newPassword.dirty && newPassword.errors?.passwordValidator"
                    >
                        {{ newPassword.errors?.passwordValidator?.message }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        class="form-control"
                        required
                        #confirmPassword="ngModel"
                        [(ngModel)]="passwordModel.confirmPassword"
                    />
                    <div
                        *ngIf="confirmPassword.dirty && confirmPassword.errors?.required"
                        class="err-msg"
                    >
                        Password confirmation is required
                    </div>
                    <div
                        *ngIf="
                            confirmPassword.dirty &&
                            passwordModel.newPassword != passwordModel.confirmPassword
                        "
                        class="err-msg"
                    >
                        Passwords must match
                    </div>
                </div>
                <div class="form-group text-center mt-5">
                    <button
                        type="submit"
                        class="btn btn-primary form-control"
                        [disabled]="!createPasswordForm.form.valid"
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    </div>
</app-layout-public>
