/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-menu.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./user-menu.component";
import * as i6 from "../../../shared/services/auth-state.service";
var styles_UserMenuComponent = [i0.styles];
var RenderType_UserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserMenuComponent, data: {} });
export { RenderType_UserMenuComponent as RenderType_UserMenuComponent };
function View_UserMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "dropdown-item"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Admin Console "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.adminUrl, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_UserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "d-inline-block user-menu"], ["ngbDropdown", ""], ["placement", "bottom-right"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 737280, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i2.ɵm]], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["aria-haspopup", "true"], ["class", "list-profile-toggle dropdown-toggle"], ["id", "dropdownUserMenu"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "axis-sprite axis-sprite-user"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0]], null, 14, "div", [["aria-labelledby", "dropdownUserMenu"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "dropdown-menu-username"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMenuComponent_1)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, [" My Profile "])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), (_l()(), i1.ɵted(-1, null, [" Sign Off "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 1, 0, currVal_1); var currVal_8 = _co.adminUrl; _ck(_v, 15, 0, currVal_8); var currVal_11 = _ck(_v, 18, 0, "/profile"); _ck(_v, 17, 0, currVal_11); var currVal_14 = _ck(_v, 22, 0, "/logout"); _ck(_v, 21, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.userName, ""); _ck(_v, 8, 0, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.userName; _ck(_v, 13, 0, currVal_7); var currVal_9 = i1.ɵnov(_v, 17).target; var currVal_10 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 21).target; var currVal_13 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_12, currVal_13); }); }
export function View_UserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-menu", [], null, null, null, View_UserMenuComponent_0, RenderType_UserMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserMenuComponent, [i6.AuthStateService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMenuComponentNgFactory = i1.ɵccf("app-user-menu", i5.UserMenuComponent, View_UserMenuComponent_Host_0, {}, {}, []);
export { UserMenuComponentNgFactory as UserMenuComponentNgFactory };
