
        <div class="qb-alert alert fade show" role="alert" *ngIf="alert" [ngClass]="alertClass">
            <div class="row justify-content-left">
                <div class="col-auto" *ngIf="alert.application">
                    <strong>{{ alert.application }}</strong>
                </div>
                <div class="col" [innerHTML]="alert.message"></div>
                <div *ngIf="alert.dismissButton" class="p-2 d-flex align-items-center">
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-info"
                        (click)="closeAlert()"
                    >
                        Dismiss
                    </button>
                </div>
            </div>
            <button
                *ngIf="!alert.dismissButton"
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeAlert()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    