import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { NgxWebstorageModule } from 'ngx-webstorage';

// security guards & services
import { AuthStateService } from './shared/services/auth-state.service';

// data services
import { APP_SETTINGS, AppSettings } from './app-settings';

// custom modules & components
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layouts/layout.module';
import { SupportModule } from './support/support.module';
import { TosModule } from './tos/tos.module';
import { LoginModule } from './login/login.module';
import { HomeModule } from './home/home.module';
import { ProfileModule } from './user-profile/profile.module';
import { ErrorModule } from './error/error.module';

import { AppComponent } from './app.component';
import { CoreModule } from './shared/core.module';
import { AppDebugComponent } from './app-debug/app-debug.component';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

export function authStateServiceFactory(authStateService: AuthStateService): Function {
    return () => authStateService.init();
}

@NgModule({
    declarations: [AppComponent, AppDebugComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        HttpClientModule,

        // custom modules
        CoreModule,
        HomeModule,
        SupportModule,
        LoginModule,
        TosModule,
        LayoutModule,
        ProfileModule,
        ErrorModule,

        NgxWebstorageModule.forRoot({ prefix: 'qb' }),
        CookieModule.forRoot(),
        Angulartics2Module.forRoot()
    ],
    providers: [
        AuthStateService,
        {
            provide: APP_INITIALIZER,
            useFactory: authStateServiceFactory,
            deps: [AuthStateService],
            multi: true
        },
        {
            provide: APP_SETTINGS,
            useValue: AppSettings
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
        angulartics2GoogleAnalytics.startTracking();
    }
}
