<div ngbDropdown class="d-inline-block help-selector" placement="bottom">
    <a id="dropdownBasic1" ngbDropdownToggle>
        <span class="axis-sprite axis-sprite-question" title="Help"></span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <a
            *ngFor="let menu of helpMenu"
            [routerLink]="[menu.link]"
            class="dropdown-item"
            target="_self"
            title="{{ menu.caption }}"
        >
            {{ menu.caption }}
        </a>
    </div>
</div>
