export class SupportTicket {
    constructor(
        public selectedApp: any = '',
        public selectedTicketType: any = '',
        public selectedReason: any = '',
        public subject: string = '',
        public description: string = ''
    ) {}

    toPayload() {
        return {
            appName: this.selectedApp.accessKey,
            ticketType: this.selectedTicketType.key,
            reason: this.selectedReason.key,
            subject: this.subject,
            description: this.description
        };
    }
}
