import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TosComponent } from './tos.component';
import { TosViewComponent } from './tos-view/tos-view.component';
import { TosAcceptComponent } from './tos-accept/tos-accept.component';
import { AuthTempGuard } from '../shared/guards/auth-temp.guard';

const routes: Routes = [
    {
        path: '' /* tos/ */,
        component: TosComponent,
        children: [
            { path: '', redirectTo: 'view', pathMatch: 'full' },
            {
                path: 'view' /* tos/view */,
                component: TosViewComponent
            },
            {
                path: 'accept' /* tos/accept */,
                component: TosAcceptComponent,
                canActivate: [AuthTempGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    declarations: []
})
export class TosRoutingModule {}
