<div
    class="dash-card"
    [class.disabled]="!app.enabled"
    [class.d-none]="app.internal && !isInternalUser && !app.enabled"
>
    <div class="dash-card-head text-center">
        <span
            class="app-icon"
            [ngStyle]="{ background: 'url(' + app.iconLarge + ')no-repeat 0 0' }"
        >
        </span>
        <h1>{{ app.name }}</h1>
    </div>
    <div class="dash-card-body">
        <div *ngIf="app.shortcuts.length">
            <ul class="list-disc">
                <li *ngFor="let link of app.shortcuts">
                    <a href="{{ link.url }}" class="ga-dash-card" *ngIf="app.enabled && link.url">
                        {{ link.name }}
                    </a>
                    <span *ngIf="!app.enabled || !link.url">{{ link.name }}</span>
                </li>
            </ul>
        </div>
        <div *ngIf="app.description">
            {{ app.description }}
        </div>
    </div>
    <div class="dash-card-footer">
        <a class="btn btn-appcard" href="{{ app.url }}" *ngIf="app.enabled && app.shortcuts.length">
            View More
        </a>
        <a
            class="btn btn-appcard"
            href="{{ app.url }}"
            *ngIf="app.enabled && !app.shortcuts.length"
        >
            Get Started
        </a>

        <a class="btn btn-appcard" *ngIf="!app.enabled" (click)="requestAccess(app)">
            Request Access
        </a>
    </div>
</div>
