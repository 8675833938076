<app-layout-public>
    <div class="splash-card-header w-100 text-center">
        <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
        <div class="splash-card-header-title">Oops! That page couldn't be found.</div>
    </div>
    <div class="splash-card-body">
        Please head back to <a [routerLink]="['/home']">the homepage</a>, and use one of the
        application cards to navigate.
    </div>
</app-layout-public>
