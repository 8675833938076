<div class="app-header">
    <a [routerLink]="['/home']"><img src="assets/logo_business.png"/></a>
    <div class="pull-right">
        <ul class="list-inline mr30">
            <li class="list-inline-item">
                <app-selector></app-selector>
            </li>
            <li class="list-inline-item">
                <app-help-selector></app-help-selector>
            </li>
            <li class="list-inline-item">
                <app-user-menu></app-user-menu>
            </li>
        </ul>
    </div>
</div>
