import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { HelpTopic } from '../../../../common/models/help.interface';
import { HelpTopicService } from './help-topic.service';

@Component({
    selector: 'app-help-topic',
    templateUrl: './help-topic.component.html',
    styleUrls: ['./help-topic.component.sass']
})
export class HelpTopicComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private topicSubs: Subscription;
    topic: HelpTopic;
    hero = {
        title: 'Welcome to Quotient Business Help',
        lead: ''
    };

    constructor(private activatedRoute: ActivatedRoute, private service: HelpTopicService) {}

    ngOnInit() {
        this.subscription = this.activatedRoute.params.subscribe(params => {
            this.topicSubs = this.service.topics.subscribe(topics => {
                this.topic = this.service.findTopic(topics, params.id);
            });
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.topicSubs.unsubscribe();
    }
}
