import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { OktaAuthService } from '../../shared/services/okta-auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../okta.widget.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
    token: string;

    constructor(private okta: OktaAuthService, private route: ActivatedRoute) {}

    ngOnInit() {
        // get url param and pass it to okta service for password reset
        this.route.paramMap.subscribe(params => {
            this.token = params.get('token');
            this.okta.resetPassword(this.token);
        });
    }
}
