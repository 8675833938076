<app-layout-public>
    <div class="splash-card-wrapper-wide">
        <div class="splash-card-header w-100 text-center">
            <img class="splash-card-header-logo" src="/assets/form_q_logo.png" />
            <div class="splash-card-header-title">Terms of Service</div>
        </div>
        <div class="splash-card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</app-layout-public>
