import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TosRoutingModule } from './tos-routing.module';
import { LayoutModule } from '../layouts/layout.module';
import { TosComponent } from './tos.component';
import { TosAcceptComponent } from './tos-accept/tos-accept.component';
import { TosViewComponent } from './tos-view/tos-view.component';
import { TosService } from './tos.service';

@NgModule({
    declarations: [TosComponent, TosAcceptComponent, TosViewComponent],
    imports: [CommonModule, LayoutModule, TosRoutingModule, RouterModule, FormsModule],
    providers: [TosService]
})
export class TosModule {}
