<div class="splash-bg-container">
    <div class="splash-bg">
        <div class="splash-img"></div>
        <div class="splash-img-overlay"></div>
    </div>
</div>
<app-header-bare></app-header-bare>
<qb-app-alert></qb-app-alert>
<div class="row">
    <div class="splash-body container">
        <div class="splash-card mx-auto">
            <ng-content></ng-content>
        </div>
    </div>
    <app-footer [isLight]="true" [isTos]="isTos"></app-footer>
</div>
