import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginNameComponent } from './login-name/login-name.component';
import { LoginNameFormComponent } from './login-name/login-name-form/login-name-form.component';
import { LayoutModule } from '../layouts/layout.module';
import { CoreModule } from '../shared/core.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RouterModule } from '@angular/router';
import { SignupFormService } from './signup/signup.service';
import { LogoutComponent } from './logout/logout.component';
import { ActivationComponent } from './activation/activation.component';
import { ActivationService } from './activation/activation.service';
import { QuotientResetPasswordComponent } from './quotient-reset-password/quotient-reset-password.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        CoreModule,
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule,
        RouterModule
    ],
    declarations: [
        SigninComponent,
        SignupComponent,
        ResetPasswordComponent,
        LoginNameComponent,
        LoginNameFormComponent,
        LogoutComponent,
        ActivationComponent,
        QuotientResetPasswordComponent
    ],
    providers: [SignupFormService, ActivationService]
})
export class LoginModule {}
