import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { Tos } from '../../../../common/entities/tos.model';
import { TosService } from '../tos.service';
import { Router } from '@angular/router';
import { ITosAcceptanceModel } from '../../../../common/models/tos-acceptance.model';

@Component({
    selector: 'app-tos-accept',
    templateUrl: './tos-accept.component.html',
    styleUrls: ['./tos-accept.component.sass']
})
export class TosAcceptComponent implements OnInit {
    tos: Tos;
    tosAcceptance: ITosAcceptanceModel;
    publishDateStr: string;
    errorMsg = '';

    constructor(
        private tosService: TosService,
        private router: Router,
        private authStateService: AuthStateService
    ) {}

    ngOnInit() {
        // populate username, tosid, acceptanceDate in tosAcceptance
        const userName = this.authStateService.getUserClaims().preferred_username;
        this.tosAcceptance = {
            username: userName,
            tos: null,
            isAccepted: false,
            acceptanceDate: new Date()
        };
        this.tosService.getTos().subscribe(
            tos => {
                this.tos = tos;
                const pubDate = new Date(tos.publishDate);
                const [date, time] = pubDate.toLocaleString('en-US').split(', ');
                this.publishDateStr = date;
                this.tosAcceptance.tos = tos;
            },
            error => (this.errorMsg = 'Failed to find ToS version' + error)
        );
    }

    accept() {
        this.tosService.postAcceptance(this.tosAcceptance).subscribe(
            response => {
                this.router.navigate(['/home']);
            },
            error => {
                this.errorMsg =
                    'Failed to submit Terms of Use acceptance. Please try again. ' + error;
            }
        );
    }
}
