<app-layout-secure>
    <div class="row justify-content-center">
        <div class="col">
            <div class="qb-content-fixed-width">
                <div class="card qb-card mt-4">
                    <div class="card-header">
                        <h4 class="card-title text-center">User Profile</h4>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center mb-4">
                            <div class="col text-center">
                                <img
                                    src="{{ profile.user_image }}"
                                    class="profile-thumbnail rounded-circle"
                                />
                                <p>{{ profile.name || profile.email }}</p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-4 text-right qb-static-label">Username</div>
                            <div class="col-5">{{ profile.username }}</div>
                        </div>
                        <div
                            class="row justify-content-center"
                            *ngIf="profile.username != profile.email"
                        >
                            <div class="col-4 text-right qb-static-label">Email Address</div>
                            <div class="col-5">{{ profile.email }}</div>
                        </div>

                        <!--
                        <div class="row justify-content-center" *ngIf="!changePwdFormShown">
                            <div class="col-4 text-right qb-static-label">Password</div>
                            <div class="col-5">
                                <button class="btn btn-link qb-btn-link" (click)="showChangePwd()">
                                    Change Password
                                </button>
                                <span class="response-msg" *ngIf="passwordChanged">
                                    The password has been updated.
                                </span>
                                <span class="pwd-err-msg" *ngIf="passwordError">
                                    Error. Please try again later.
                                </span>
                            </div>
                        </div>
                        -->

                        <div class="row justify-content-center">
                            <div class="col-9">
                                <div *ngIf="changePwdFormShown">
                                    <form
                                        (ngSubmit)="submitChangePassword()"
                                        #changePasswordForm="ngForm"
                                        class="qb-form"
                                    >
                                        <div class="form-group">
                                            <label for="oldPassword">Old Password</label>
                                            <input
                                                type="password"
                                                class="form-control"
                                                id="oldPassword"
                                                required
                                                [(ngModel)]="model.oldPassword"
                                                name="oldPassword"
                                                #oldPassword="ngModel"
                                            />
                                            <div
                                                [hidden]="oldPassword.valid || oldPassword.pristine"
                                                class="alert alert-danger"
                                            >
                                                Old Password is required
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="newPassword">New Password</label>
                                            <input
                                                type="password"
                                                class="form-control"
                                                id="newPassword"
                                                required
                                                [(ngModel)]="model.newPassword"
                                                name="newPassword"
                                                #newPassword="ngModel"
                                            />
                                            <div
                                                [hidden]="
                                                    newPassword.pristine || newPasswordIsValid()
                                                "
                                                class="alert alert-danger"
                                            >
                                                Your password must have at least 8 characters, a
                                                lowercase letter, an uppercase letter, a number, no
                                                parts of your username.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="confirmPassword">Confirm Password</label>
                                            <input
                                                type="password"
                                                class="form-control"
                                                id="confirmPassword"
                                                required
                                                [(ngModel)]="model.confirmPassword"
                                                name="confirmPassword"
                                                #confirmPassword="ngModel"
                                            />
                                            <div
                                                [hidden]="
                                                    model.confirmPassword === model.newPassword ||
                                                    confirmPassword.pristine
                                                "
                                                class="alert alert-danger"
                                            >
                                                Passwords should match
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button
                                                type="submit"
                                                class="btn btn-primary"
                                                [disabled]="
                                                    !changePasswordForm.form.valid ||
                                                    !newPasswordIsValid() ||
                                                    model.confirmPassword !== model.newPassword
                                                "
                                            >
                                                Update
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary"
                                                (click)="hideChangePwd()"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="card qb-card">
                    <div class="card-header">
                        <h4 class="card-title text-center">Contact Information</h4>
                    </div>
                    <div class="card-body">
                        <form novalidate class="qb-form ml-5 mr-5">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.firstName
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.lastName
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Title</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.title || '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Office Phone</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.officePhone || '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Mobile Phone</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.mobilePhone || '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Street</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.street || '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.city || '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Zip</label>
                                <div class="col-sm-10">
                                    <span class="form-control-plaintext">{{
                                        profile?.zip || '-'
                                    }}</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layout-secure>
