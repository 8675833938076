import { throwError as observableThrowError, ReplaySubject, Observable, Subscription } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiResponse } from '../../../../common/models/api-response.interface';
import { TicketPicklist } from '../../../../common/models/support-picklist.interface';
import { APP_SETTINGS } from '../../app-settings';

export class CreateTicketPicklistService {
    private api;
    private status;
    private picklist: ReplaySubject<TicketPicklist[]>;
    private subs: Subscription;

    constructor(@Inject(APP_SETTINGS) private appSettings, private http: HttpClient) {
        this.api = this.appSettings.apiEndpoint.concat('support/');
        this.status = this.appSettings.NOT_LOADED;
        this.picklist = new ReplaySubject(1);
    }

    refresh() {
        this.status = this.appSettings.LOADING;
        this.subs = this.http
            .get<ApiResponse<TicketPicklist[]>>(`${this.api}picklist`)
            .pipe(
                map(res => {
                    this.status = this.appSettings.LOADED;
                    this.picklist.next(res.data);
                }),
                catchError(error => {
                    this.status = this.appSettings.NOT_LOADED;
                    return observableThrowError(error);
                })
            )
            .subscribe();
    }

    get ticketPicklist() {
        if (this.status === this.appSettings.NOT_LOADED) {
            this.refresh();
        }
        return this.picklist.asObservable();
    }
}
