/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-selector.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./help-selector.component";
var styles_HelpSelectorComponent = [i0.styles];
var RenderType_HelpSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpSelectorComponent, data: {} });
export { RenderType_HelpSelectorComponent as RenderType_HelpSelectorComponent };
function View_HelpSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "dropdown-item"], ["target", "_self"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = "_self"; var currVal_4 = _ck(_v, 2, 0, _v.context.$implicit.link); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.caption, ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.caption; _ck(_v, 3, 0, currVal_5); }); }
export function View_HelpSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "d-inline-block help-selector"], ["ngbDropdown", ""], ["placement", "bottom"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 737280, null, 3, i4.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i4.ɵm]], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["id", "dropdownBasic1"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "axis-sprite axis-sprite-question"], ["title", "Help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "dropdownBasic1"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 1, i4.NgbDropdownMenu, [i4.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpSelectorComponent_1)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_1); var currVal_6 = _co.helpMenu; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_5 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_HelpSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-selector", [], null, null, null, View_HelpSelectorComponent_0, RenderType_HelpSelectorComponent)), i1.ɵdid(1, 49152, null, 0, i5.HelpSelectorComponent, [], null, null)], null, null); }
var HelpSelectorComponentNgFactory = i1.ɵccf("app-help-selector", i5.HelpSelectorComponent, View_HelpSelectorComponent_Host_0, {}, {}, []);
export { HelpSelectorComponentNgFactory as HelpSelectorComponentNgFactory };
