import { Component } from '@angular/core';
import { AppAlertService } from '../../services/app-alert.service';
import { Alert } from '../../../../../common/models/alert.interface';

@Component({
    selector: 'qb-app-alert',
    styleUrls: ['./alert.component.sass'],
    template: `
        <qb-alert [alert]="currentAlert" (onAlertClose)="handleAlertClosed($event)"></qb-alert>
    `
})
export class AppAlertComponent {
    constructor(private appAlertService: AppAlertService) {}

    get currentAlert() {
        return this.appAlertService.getCurrentAlert();
    }

    handleAlertClosed(alert: Alert) {
        this.appAlertService.close(alert);
    }
}
