<div class="row bg-help">
    <div class="col">
        <div class="qb-hero-support">
            <app-hero [hero]="hero"></app-hero>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="flex-container">
            <div class="flex-item">
                <div class="card">
                    <div class="card-body">
                        <section class="topic-container">
                            <h1 class="section-title">Help Topic</h1>
                            <div class="help-topics">
                                <div
                                    *ngFor="let topic of topics"
                                    class="topic"
                                    (click)="onTopicSelect(topic)"
                                >
                                    <span class="topic-icons" ngClass="{{ topic.icon }}"></span>
                                    <h4 class="block-caption">{{ topic.caption }}</h4>
                                </div>
                            </div>
                        </section>
                        <section class="brick brick-support">
                            <div class="brick-body">
                                <h1 class="section-title">
                                    Having trouble and cannot find a solution?
                                </h1>
                                <a
                                    [routerLink]="['/support/create-ticket']"
                                    id="ga-create-ticket-2"
                                    class="btn btn-primary"
                                >
                                    Create a support ticket!
                                </a>
                            </div>
                        </section>
                        <section class="faq-links">
                            <h1 class="section-title">APPs Help</h1>
                            <div class="faq-blocks">
                                <a
                                    href="{{ helplink.url }}"
                                    target="_blank"
                                    class="faq-block-item"
                                    *ngFor="let helplink of helpLinks"
                                >
                                    <span
                                        class="app-icons"
                                        [ngStyle]="{
                                            background: 'url(' + helplink.icon + ') no-repeat 0 0'
                                        }"
                                    ></span>
                                    <h4 class="block-caption">{{ helplink.caption }}</h4>
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
