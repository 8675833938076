import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OrderModule } from 'ngx-order-pipe';

import { SupportRoutingModule } from './support-routing.module';
import { LayoutModule } from '../layouts/layout.module';
import { CoreModule } from '../shared/core.module';

import { ViewTicketComponent } from './view-ticket/view-ticket.component';
import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { ListTicketsComponent } from './list-tickets/list-tickets.component';
import { SupportComponent } from './support.component';
import { HelpTopicComponent } from './help-topic/help-topic.component';
import { HelpComponent } from './help/help.component';
import { SupportService } from './support.service';
import { HelpTopicService } from './help-topic/help-topic.service';
import { ListTicketService } from './list-tickets/list-ticket.service';
import { CreateTicketPicklistService } from './create-ticket/create-ticket-picklist.service';

@NgModule({
    declarations: [
        SupportComponent,
        ViewTicketComponent,
        CreateTicketComponent,
        ListTicketsComponent,
        HelpComponent,
        HelpTopicComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        SupportRoutingModule,
        FormsModule,
        LayoutModule,
        CoreModule,
        OrderModule
    ],
    providers: [SupportService, HelpTopicService, ListTicketService, CreateTicketPicklistService]
})
export class SupportModule {}
