/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tos.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../layouts/layout-public/layout-public.component.ngfactory";
import * as i3 from "../layouts/layout-public/layout-public.component";
import * as i4 from "@angular/router";
import * as i5 from "./tos.component";
import * as i6 from "./tos.service";
var styles_TosComponent = [i0.styles];
var RenderType_TosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TosComponent, data: {} });
export { RenderType_TosComponent as RenderType_TosComponent };
export function View_TosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-layout-public", [], null, null, null, i2.View_LayoutPublicComponent_0, i2.RenderType_LayoutPublicComponent)), i1.ɵdid(1, 49152, null, 0, i3.LayoutPublicComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["class", "splash-card-wrapper-wide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "splash-card-header w-100 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "splash-card-header-logo"], ["src", "/assets/form_q_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "splash-card-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms of Service"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "splash-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 9, 0); }, null); }
export function View_TosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tos", [], null, null, null, View_TosComponent_0, RenderType_TosComponent)), i1.ɵdid(1, 114688, null, 0, i5.TosComponent, [i6.TosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TosComponentNgFactory = i1.ɵccf("app-tos", i5.TosComponent, View_TosComponent_Host_0, {}, {}, []);
export { TosComponentNgFactory as TosComponentNgFactory };
