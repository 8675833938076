/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-secure.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header-full.component.ngfactory";
import * as i3 from "../header/header-full.component";
import * as i4 from "../../shared/components/alert/app-alert.component.ngfactory";
import * as i5 from "../../shared/components/alert/app-alert.component";
import * as i6 from "../../shared/services/app-alert.service";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "../../shared/services/app-config.service";
import * as i10 from "./layout-secure.component";
var styles_LayoutSecureComponent = [i0.styles];
var RenderType_LayoutSecureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutSecureComponent, data: {} });
export { RenderType_LayoutSecureComponent as RenderType_LayoutSecureComponent };
export function View_LayoutSecureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header-full", [], null, null, null, i2.View_HeaderFullComponent_0, i2.RenderType_HeaderFullComponent)), i1.ɵdid(2, 49152, null, 0, i3.HeaderFullComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "qb-app-alert", [], null, null, null, i4.View_AppAlertComponent_0, i4.RenderType_AppAlertComponent)), i1.ɵdid(4, 49152, null, 0, i5.AppAlertComponent, [i6.AppAlertService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "main", [["class", "col"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(8, 49152, null, 0, i8.FooterComponent, [i9.AppConfigService], null, null)], null, null); }
export function View_LayoutSecureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-secure", [], null, null, null, View_LayoutSecureComponent_0, RenderType_LayoutSecureComponent)), i1.ɵdid(1, 114688, null, 0, i10.LayoutSecureComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutSecureComponentNgFactory = i1.ɵccf("app-layout-secure", i10.LayoutSecureComponent, View_LayoutSecureComponent_Host_0, {}, {}, ["*"]);
export { LayoutSecureComponentNgFactory as LayoutSecureComponentNgFactory };
