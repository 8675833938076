/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./external-error.component";
import * as i2 from "@angular/router";
import * as i3 from "../shared/services/app-alert.service";
import * as i4 from "../shared/services/app-list.service";
var styles_ExternalErrorComponent = [];
var RenderType_ExternalErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExternalErrorComponent, data: {} });
export { RenderType_ExternalErrorComponent as RenderType_ExternalErrorComponent };
export function View_ExternalErrorComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ExternalErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ExternalErrorComponent", [], null, null, null, View_ExternalErrorComponent_0, RenderType_ExternalErrorComponent)), i0.ɵdid(1, 114688, null, 0, i1.ExternalErrorComponent, [i2.ActivatedRoute, i2.Router, i3.AppAlertService, i4.AppListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalErrorComponentNgFactory = i0.ɵccf("ExternalErrorComponent", i1.ExternalErrorComponent, View_ExternalErrorComponent_Host_0, {}, {}, []);
export { ExternalErrorComponentNgFactory as ExternalErrorComponentNgFactory };
