import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OktaAuthService } from '../../shared/services/okta-auth.service';
import { AuthStateService } from '../../shared/services/auth-state.service';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['../okta.widget.sass'],
    encapsulation: ViewEncapsulation.None
})
export class SigninComponent implements OnInit {
    constructor(private okta: OktaAuthService, private authStateService: AuthStateService) {}

    ngOnInit() {
        this.okta.login(
            this.authStateService.getSavedUsername() || this.authStateService.getTempUsername()
        );
    }
}
