import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationService, PasswordForm } from './activation.service';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { Okta } from '../../../../common/models/okta.interface';
import PasswordPolicy = Okta.PasswordPolicy;

const activationTokenRegex = /welcome\/([^\/]*)/g;

@Component({
    selector: 'app-welcome',
    templateUrl: './activation.component.html'
})
export class ActivationComponent {
    state: string = 'begin';
    activationToken: string;
    name: string;
    username: string;
    isInternalUser: boolean;

    passwordModel: PasswordForm = {
        newPassword: null,
        confirmPassword: null
    };
    passwordPolicy: PasswordPolicy;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private activationService: ActivationService,
        private authStateService: AuthStateService
    ) {}

    ngOnInit() {
        this.name = this.route.snapshot.queryParams['name'];
        this.username = this.route.snapshot.queryParams['username'];

        let activationLink = this.route.snapshot.queryParams['activationLink'];
        let matcher = activationTokenRegex.exec(activationLink);
        if (matcher && matcher.length > 1) {
            this.activationToken = matcher[1];
        }

        if (!(this.activationToken && this.name && this.username)) {
            this.state = 'ERROR';
        } else {
            // Require bare minimum information before continuing
            this.isInternalUser = this.authStateService.isInternalUser(this.username);
            if (this.isInternalUser) {
                this.authStateService.saveTempUsername(this.username);
            }
        }
    }

    start() {
        // Bypass activation for internal users
        if (this.isInternalUser) {
            this.router.navigate(['/signin']);
        } else {
            this.activationService
                .activateUser(this.username, this.activationToken)
                .then(newState => {
                    this.state = newState;
                    this.passwordPolicy = this.activationService.getStatePasswordPolicy();
                });
        }
    }

    createPassword() {
        this.activationService.createPassword(this.username, this.passwordModel).then(newState => {
            if (newState !== 'ERROR') {
                this.authStateService.doActivationHandoff(
                    this.username,
                    this.passwordModel.newPassword,
                    this.router
                );
            }
        });
    }
}
