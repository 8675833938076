import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SignupUser } from '../../../../common/models/signup-user.interface';
import { SignupFormService } from './signup.service';
import { AppConfigService } from '../../shared/services/app-config.service';
import { SignupForm } from '../models/signup-form';
import { AppAlertService } from '../../shared/services/app-alert.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.sass']
})
export class SignupComponent {
    user: SignupUser;
    isSubmitted = false;
    inProgress = false;
    captchaKey: string;

    constructor(
        private router: Router,
        private signupService: SignupFormService,
        private appConfigService: AppConfigService
    ) {}

    ngOnInit() {
        this.user = new SignupForm();
        this.appConfigService.config.subscribe(appConfig => {
            this.captchaKey = appConfig.captchaPublicKey;
        });
    }

    onSubmit(isValid: boolean) {
        if (isValid) {
            this.inProgress = true;
            this.signupService.signup(this.user).subscribe(data => {
                this.inProgress = false;
                if (data.responseSuccess) {
                    this.isSubmitted = true;
                }
            });
        }
    }

    cancel() {
        this.router.navigate(['/login']);
    }

    backToLogin() {
        this.router.navigate(['/login']);
    }
}
