import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';
import { ListTicketsComponent } from './list-tickets/list-tickets.component';
import { SupportComponent } from './support.component';
import { AuthValidGuard } from '../shared/guards/auth-valid.guard';
import { HelpComponent } from './help/help.component';
import { HelpTopicComponent } from './help-topic/help-topic.component';

const routes: Routes = [
    {
        path: '' /* support/ */,
        component: SupportComponent,
        children: [
            {
                path: '',
                redirectTo: 'help',
                pathMatch: 'full',
                canActivate: [AuthValidGuard]
            },
            {
                path: 'help' /* support/help */,
                component: HelpComponent,
                canActivate: [AuthValidGuard]
            },
            {
                path: 'help/:id' /* support/help/2/topic */,
                component: HelpTopicComponent,
                canActivate: [AuthValidGuard]
            },
            {
                path: 'tickets' /* support/tickets */,
                component: ListTicketsComponent,
                canActivate: [AuthValidGuard]
            },
            {
                path: 'create-ticket' /* support/create-ticket */,
                component: CreateTicketComponent,
                canActivate: [AuthValidGuard]
            },
            {
                path: 'tickets/:id' /* support/tickets/PT-1 */,
                component: ViewTicketComponent,
                canActivate: [AuthValidGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    declarations: []
})
export class SupportRoutingModule {}
