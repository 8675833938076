import { NgModule } from '@angular/core';

import { ProgressComponent } from './components/progress/progress.component';
// security guards & services
import { OktaAuthService } from './services/okta-auth.service';
import { AppConfigService } from './services/app-config.service';
import { AppListService } from './services/app-list.service';
import { AuthValidGuard } from './guards/auth-valid.guard';
import { AuthInvalidGuard } from './guards/auth-invalid.guard';
import { LoginParametersGuard } from './guards/login-parameter.guard';
import { SigninUsernameGuard } from './guards/signin-username.guard';
import { AuthTempGuard } from './guards/auth-temp.guard';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PasswordValidatorDirective } from './components/password-validator.directive';
import { FilesizePipe } from './pipes/filesize.pipe';
import { TruncNamePipe } from './pipes/trunc-name.pipe';
import { AppAlertService } from './services/app-alert.service';
import { AlertComponent } from './components/alert/alert.component';
import { CommonModule } from '@angular/common';
import { AppAlertComponent } from './components/alert/app-alert.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInvalidErrorGuard } from './guards/auth-invalid-error.guard';
import { HeroComponent } from './components/hero/hero.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ErrorRedirectGuard } from './guards/error-redirect.guard';

@NgModule({
    declarations: [
        ProgressComponent,
        SpinnerComponent,
        PasswordValidatorDirective,
        FilesizePipe,
        TruncNamePipe,
        AlertComponent,
        AppAlertComponent,
        HeroComponent,
        BreadcrumbComponent
    ],
    exports: [
        ProgressComponent,
        SpinnerComponent,
        PasswordValidatorDirective,
        FilesizePipe,
        TruncNamePipe,
        AlertComponent,
        AppAlertComponent,
        HeroComponent,
        BreadcrumbComponent
    ],
    providers: [
        OktaAuthService,
        AppConfigService,
        AuthValidGuard,
        AuthInvalidGuard,
        AuthInvalidErrorGuard,
        AuthTempGuard,
        LoginParametersGuard,
        ErrorRedirectGuard,
        SigninUsernameGuard,
        AppListService,
        AppAlertService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
    ],
    imports: [CommonModule]
})
export class CoreModule {}
