import { throwError as observableThrowError, ReplaySubject, Observable, Subscription } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APP_SETTINGS } from '../../app-settings';
import { ApiResponse } from '../../../../common/models/api-response.interface';

export abstract class AbstractCachingApi<T> {
    protected subject: ReplaySubject<T>;
    protected subscription: Subscription;
    protected status: string;

    constructor(protected http: HttpClient, @Inject(APP_SETTINGS) protected appSettings) {
        this.status = this.appSettings.NOT_LOADED;
        this.subject = new ReplaySubject(1);
    }

    public get list(): Observable<T> {
        if (this.status === this.appSettings.NOT_LOADED) {
            this.refreshList();
        }
        return this.subject.asObservable();
    }

    protected abstract get baseUrl(): string;

    public refreshList() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        this.status = this.appSettings.LOADING;
        this.subscription = this.http
            .get<ApiResponse<T>>(this.baseUrl)
            .pipe(
                map(res => {
                    this.status = this.appSettings.LOADED;
                    this.subject.next(res.data);
                }),
                catchError(error => {
                    this.status = this.appSettings.NOT_LOADED;
                    let errMsg = error.message
                        ? error.message
                        : error.status
                        ? `${error.status} - ${error.statusText}`
                        : 'Server error loading available apps';
                    console.error(errMsg);
                    return observableThrowError(errMsg);
                })
            )
            .subscribe();
    }
}
