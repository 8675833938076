import { take } from 'rxjs/operators';
import { Component } from '@angular/core';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { AppConfigService } from '../../shared/services/app-config.service';
import { AppAlertService } from '../../shared/services/app-alert.service';

@Component({
    selector: 'app-login-name',
    templateUrl: './login-name.component.html',
    styleUrls: ['./login-name.component.sass']
})
export class LoginNameComponent {
    constructor(
        private appConfigService: AppConfigService,
        private appAlertService: AppAlertService
    ) {
        appConfigService.config.pipe(take(1)).subscribe(appConfig => {
            appConfig.systemAlerts.forEach(alert => appAlertService.queueAlert(alert));
        });
    }
}
