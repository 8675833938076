import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Tos, TosAcceptance } from '../../../common/entities/tos.model';
import { ApiResponse } from '../../../common/models/api-response.interface';
import { ITosAcceptanceModel } from '../../../common/models/tos-acceptance.model';

export class TosAcceptanceError extends Error {
    constructor(public message: string, public reason: string) {
        super(message);

        // Babel 5 does not transpile extension of native object types.
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, TosAcceptanceError.prototype);
    }
}

@Injectable()
export class TosService {
    private urlTos = '/api/tos/';
    private urlAcceptance = '/api/tos_acceptance/';

    constructor(private http: HttpClient) {}

    postAcceptance(tosAcceptance: ITosAcceptanceModel): Observable<JSON | any> {
        return this.http.post(this.urlAcceptance, tosAcceptance);
    }

    // get latest version
    getTos(): Observable<Tos> {
        return this.http
            .get<ApiResponse<Tos>>(this.urlTos)
            .pipe(map(apiResponse => apiResponse.data));
    }

    // get TOS acceptance for the user
    getAcceptance(username): Observable<ITosAcceptanceModel> {
        return this.http.get<ApiResponse<ITosAcceptanceModel>>(this.urlAcceptance + username).pipe(
            catchError((error: Response | any) => {
                // Handle validation errors, throw others
                if (error && error.status === 409) {
                    return observableThrowError(new TosAcceptanceError(error.message, '409'));
                } else {
                    return observableThrowError(error);
                }
            }),
            map(apiResponse => apiResponse.data)
        );
    }
}
