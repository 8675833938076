import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthStateService } from '../services/auth-state.service';
import { TosAcceptanceError, TosService } from '../../tos/tos.service';

@Injectable()
export class AuthValidGuard implements CanActivate {
    constructor(
        private authStateService: AuthStateService,
        private router: Router,
        private tosService: TosService
    ) {}

    /**
     * canActivate is a must-have method, which should return a boolean
     * @param route
     * @param state
     * @returns {boolean}
     */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authStateService.hasTokenCookie() && !this.authStateService.hasOnlyTempToken()) {
            // if no tokens, redirect to login
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return observableOf(false);
        } else if (this.authStateService.hasOnlyTempToken()) {
            // if only temp token then check TOS acceptance.
            return this.tosService.getAcceptance(this.authStateService.getUsername()).pipe(
                // if TOS is ok, setToken and check if redirect is necessary
                map(response => {
                    this.authStateService.confirmTempToken();
                    if (this.authStateService.hasPendingRedirect()) {
                        // Prevent page loads if pending redirects
                        this.authStateService.handleAuthRedirect(this.router);
                        return false;
                    } else {
                        return true;
                    }
                }),
                catchError(error => {
                    if (error instanceof TosAcceptanceError) {
                        // if TOS is missing, redirect to TOS acceptance
                        this.router.navigate(['/tos/accept']);
                        return observableOf(false);
                    } else {
                        return observableThrowError(error);
                    }
                })
            );
        } else if (this.authStateService.hasPendingRedirect()) {
            // Prevent page loads if pending redirects
            this.authStateService.handleAuthRedirect(this.router);
            return observableOf(false);
        } else {
            // current view route and state are ignored, as the decision is based only on auth service response
            return observableOf(true);
        }
    }
}
